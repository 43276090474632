/* eslint-disable */


import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal
} from "reactstrap";

import classnames from "classnames";
import Select from "react-select";
import Dropzone from "react-dropzone";
import { useSelector } from "react-redux";
// Redux
import { withRouter, Link } from "react-router-dom";
// availity-reactstrap-validation
import {
  AvForm,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
import SweetAlert from "react-bootstrap-sweetalert"
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import { Fn_DisplayData, Fn_AddEditData, Fn_FillListData, Fn_GetReport, Fn_ChangeStateValue } from "../../store/functions";
import axios from "axios";

function getdate() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');

  return  `${year}-${month}-${day}`;

}




class pageAddEdit_Deposit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      formData: {},
      activeTab: 1,
      passedSteps: [1],
      //dropdowns
      Accountdetail: [{
        Name : ""
      }],
      RecipentDetail: [
        {
          Name : ""
        }
      ],
      prov : false,
      maritalStatus: [],
      accounttypevalue: 0,
      bloodGroup: [],
      gender: [],
      education: [],
      profession: [],
      relationShip: [],
      nationality: [],
      typeofRegCert: [],
      accounttype : [],
      SAccountType :0,
     
            BoldToken : [{
              Token : ''
            }],
      Account  : [],
      success_msg : false,
      VoucherId : 0,
      PreLoanDetails: [{}],
      IsAPR : false,
      Interest : [{
        MainInterest : 0
      }],
      gridData: [],
      modal_fullscreen: false,
      dynamic_title: "",
      dynamic_description: "",
      page: 1,
      sizePerPage: 10,
      productData: []
    };

  
  
    this.toggleTab.bind(this);
    this.obj = this;
    this.formTitle = "Deposit ";
    this.breadCrumbTitle = "Deposit ";
    this.breadCrumbItem =  this.formTitle;
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/Membership";
    this.API_URL_SAVE = API_WEB_URLS.TransactionDeposit + "/0/token";
    this.pushFormName = "/dashboard";
  

    //Event Binding
    this.btnSave_onClick = this.btnSave_onClick.bind(this);
    this.btnCancel_onClick = this.btnCancel_onClick.bind(this);
    this.syno =  this.syno.bind(this);
    
   
    this.btnReset_onClick  =  this.btnReset_onClick.bind(this);
   
   

  }




  componentDidMount() {
    //Filling DropDowns
    Fn_FillListData(this.obj, "BoldToken", API_WEB_URLS.MASTER + "/0/token/BoldToken/Id/0");
    Fn_FillListData(this.obj, "Interest", API_WEB_URLS.MASTER + "/0/token/SchemeInterest/Id/"+1);

     Fn_FillListData(this.obj, "AccountTypeScheme", API_WEB_URLS.MASTER + "/0/token/AccountTypeSchemesByAccountType/Id/14");
      
    
    const obj = JSON.parse(localStorage.getItem("authUser"));
    this.setState({ MembershipNo: obj.name, Name: obj.username, aid: obj.uid , role:obj.role });
    this.GetMemberName(obj.name);

    const { id } = this.props.match.params;
    if (id) {
      this.setState({ id: id });
      this.breadCrumbItem = "Edit " + this.formTitle;
      Fn_DisplayData(this.obj, id, this.API_URL + "/Id");
    } else {
      this.setState({ id: 0 });
    }
  }



  btnSave_onClick = async(event, formData)=> {




   
if (Number(this.state.Amount)>0){

  const url = 'https://api.paystack.co/transaction/initialize';
  const headers = {
    Authorization: 'Bearer sk_live_1c89137206b2b42071d8cd6d8eba561ada311472',
    'Content-Type': 'application/json'
  };
  const amount = Number(this.state.Amount) * 100; // Convert the amount to cents if it's in dollars
  const charge = amount * 0.03; // Calculate 2% of the amount
  
  const formData = {
    email: this.state.PreLoanDetails[0].Email,
    amount: Number(amount)+Number(charge),
  };

  axios.post(url, formData, { headers })
    .then((response) => {
      if (response.data.status) {
       
        // Redirect the user to the authorization URL
        window.open(response.data.data.authorization_url, '_blank');
        //window.location.href = response.data.data.authorization_url;
      } else {
        // Handle any error cases here
        console.error('Payment error:', response.data.message);
      }
    })
    .catch((error) => {
      // Handle any errors here
      console.error('Payment error:', error);
    });




}

else {
  alert('Please enter valid Amount!');
}

    
  }



  btnCancel_onClick = event => {
    event.preventDefault();
    //this.props.history.goBack();
    this.props.history.push(this.pushFormName);
  };

  
  btnReset_onClick = event => {
    this.setState({
      Balance : [{
        Balance : 0
       }],
      MemberName : [
  {
    Name : ''
  }
      ],
      MembershipNo : ''
    });
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        var modifiedSteps = [...this.state.passedSteps, tab];
        this.setState({
          activeTab: tab,
          passedSteps: modifiedSteps,
        });
      }
    }
  }



  GetAccountDetail =(event)=> {
    var value  =  event.target.value;
    Fn_FillListData(this.obj, "Accountdetail", API_WEB_URLS.MASTER + "/0/token/ListOfAccountsByMember/Id/"+this.state.MemberName[0].Id);
  }



  syno () {

    this.setState({
      success_msg : false
    });

      };





  GetMemberName =(e)=> {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    if(e == 3 || e == 12){
      var value = event.target.value;
      value =  value + '-';
      
      this.setState({
        MembershipNo: value
      });
    }


    let vformData = new FormData();
    vformData.append("Id", obj.uid);
    Fn_GetReport(this.obj, {  arguList: {id: 0, formData: vformData } }, "MonthlyLoanLimit/0/token", "PreLoanDetails", true );
    

  }




 




 





 




  render() {

   

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.breadCrumbTitle}
              breadcrumbItem={this.breadCrumbItem}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                   
                    <div className="wizard clearfix">
                      <div className="content clearfix">
                            
                            <AvForm className="needs-validation" onValidSubmit={this.btnSave_onClick}>
                         
                                <Row>
                                  <Col lg="12">
                                  <Card>
                                    <CardBody>
                                      <h4 className="card-title mb-4">Deposit To Transaction Account</h4>
                                      <Row>


                                      <Row>
                                        <Col sm="2">
                                          <label htmlFor="membershipType" className="col-form-label">Deposit Date</label>
                                        </Col>
                                        <Col sm="6">
                                        <AvField name="OpeningDate"   value={this.state.formData.OpeningDate == undefined ? getdate() : this.state.formData.OpeningDate}   type="date" className="form-control"  disabled />
                                        </Col>
                                                                              </Row>

                                        <Col sm="2">
                                                    <label htmlFor="membershipType" className="col-form-label">Member GIN</label>
                                                  </Col>
                                          <Col lg="4">

                                          <AvField name="membershipno" onChange={this.GetMemberName}  value={this.state.MembershipNo} placeholder="Member GIN" errorMessage="Enter Member GIN" validate={{ required: { value: true } }} type="text" disabled className="form-control" />
                                          
                                          </Col>

                                         
                                        </Row>

                                        <Row>
                                        <Col sm="2">
                                          <label htmlFor="membershipType" className="col-form-label"> First Name</label>
                                        </Col>
                                        <Col sm="4">
                                        <AvField name="FirstName"  value={this.state.PreLoanDetails[0].FirstName}  placeholder=" FirstName"   type="text" disabled className="form-control" />
                                         
                                        </Col>

                                        <Col sm="2">
                                          <label htmlFor="membershipType" className="col-form-label"> Last Name</label>
                                        </Col>
                                        <Col sm="4">
                                        <AvField name="LastName"  value={this.state.PreLoanDetails[0].LastName}  placeholder=" Last Name"   type="text" disabled className="form-control" />
                                         
                                        </Col>



                                        </Row>


                                       
                                      <Row>
                                        <Col sm="2">
                                          <label htmlFor="membershipType" className="col-form-label">Email Address</label>
                                        </Col>
                                        <Col sm="4">
                                          <AvField  name="Email" label="" value={this.state.PreLoanDetails[0].Email} placeholder="Email"  type="text" className="form-control"  disabled>
                                           
                                          </AvField>
                                        </Col>

                                        


                                      </Row>



                                      <Row>
                                        <Col sm="2">
                                          <label htmlFor="membershipType" className="col-form-label">Phone Number</label>
                                        </Col>
                                        <Col sm="4">
                                          <AvField  name="PhoneNo" label="" value={this.state.PreLoanDetails[0].PhoneNo} placeholder="PhoneNo"  type="text" className="form-control"  disabled>
                                           
                                          </AvField>
                                        </Col>

                                      </Row>


                                      
                                      <Row>
                                        <Col sm="2">
                                          <label htmlFor="membershipType" className="col-form-label">Member Date Of Birth</label>
                                        </Col>
                                        <Col sm="4">
                                          <AvField  name="DOB" label="" value={this.state.PreLoanDetails[0].DOB} placeholder="DOB"  type="text" className="form-control"  disabled>
                                           
                                          </AvField>
                                        </Col>

                                      </Row>



                                      <Row>
                                        <Col sm="2">
                                          <label htmlFor="membershipType" className="col-form-label">Amount</label>
                                        </Col>
                                        <Col sm="4">
                                          <AvField  name="Amount" label="" value={this.state.Amount} onChange={(event)=> Fn_ChangeStateValue(this.obj, "Amount", event.target.value)} placeholder="Amount"  type="number" className="form-control"  >
                                           
                                          </AvField>
                                        </Col>



                                      </Row>

                                      





                               

         

                          <div className="d-flex flex-wrap gap-2">
                            <Button type="submit" color="primary">
                              Deposit
                            </Button>{" "}

                            {this.state.success_msg ? (
                      <SweetAlert
                        title="Loan Created Successful!"
                        success
                        confirmBtnBsStyle="success"
                        onConfirm={this.syno}
                      >
                       
                      </SweetAlert>
                    ) : null}
                           
                            
                            
                            
                            {" "}
                                <Button
                          type="button"
                          color="secondary"
                          className="waves-effect"
                          onClick={this.btnCancel_onClick}
                        >
                              Exit
                            </Button>

                            
                          </div>

</CardBody>
                      </Card>
                    </Col>
                    
                      </Row>
                    
                      </AvForm>
                      </div>
                    </div>
                  </CardBody>
                </Card>

        
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default compose(container)(pageAddEdit_Deposit);
