import React, { Component } from "react";

    //Import Breadcrumb
    import RCDisplayPage from "../../components/Common/RCDisplayPage";
    //Constants
    import { API_WEB_URLS } from "../../constants/constAPI";
    //Store
    import { compose } from "recompose";
    import { container } from "../../store/Containers/cntCommon";
    import {
      Fn_FillListData,
      togglemodal,
      toggleDeleteConfirm,
      toggleDeleteSuccess,
      Fn_DeleteData,
    } from "../../store/functions";
    
    class pageAddEdit_SellBusiness extends Component {
      constructor(props) {
        super(props);
    
        this.state = {
          modal: false,
          selectedFormData: {},
          gridData: [],
          confirm_alert: false,
          success_dlg: false,
          dynamic_title: "",
          dynamic_description: "",
        };
        this.obj = this;
        this.breadCrumbTitle = "Masters";
        this.breadCrumbItem = "Sell Business";
        this.modalTitle = "Sell Business";
        this.rtPage_Add = "Add-SellBusiness";
        this.rtPage_Edit = "Edit-SellBusiness";
        this.API_URL = API_WEB_URLS.MASTER + "/0/token/GetNewOpportunity";
    
        //Event Binding
        this.btnAdd_onClick = this.btnAdd_onClick.bind(this);
        this.btnEdit_onClick = this.btnEdit_onClick.bind(this);
        this.btnDelete_onClick = this.btnDelete_onClick.bind(this);
      }
      componentDidMount() {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        Fn_FillListData(this.obj, "gridData", this.API_URL+ "/F_MemberMaster/"+obj.uid);      
      }
      btnAdd_onClick(event, values) {
        this.props.history.push(`${this.rtPage_Add}`);
      }
      btnEdit_onClick(formData) {
        this.props.history.push(`${this.rtPage_Edit}/${formData.Id}`, {});
      }
      btnDelete_onClick(formData) {
        Fn_DeleteData(this.obj, formData.Id, this.API_URL, this.API_URL + "/Id/0");
      }
      renderGridHeader() {



        return (
          <>
           
            <th>DateOfCreation</th> 
            <th>Description</th>
            <th>IsActive</th>
        
    
          </>
        );
      }
      renderGridBody(formData) {
        return (
          <>
           
            <td>{formData.DateOfCreation}</td>
            <td>{formData.Description}</td>
            <td>
           
                                        <div className="form-check form-switch form-switch-lg mb-3">
                                        
                                        
                                          <input
                                            type="checkbox"
                                            className="form-check-input"
                                            checked={formData.IsFileUpload}
                                            id="customSwitchsizelg"
                                          //   onChange={() => {
                                          //     this.setState(prevState => ({
                                          //       IsFileUpload:
                                          //         !prevState.IsFileUpload,
                                          //       formData: {
                                          //         ...prevState.formData,
                                          //         IsFileUpload:
                                          //           !prevState.IsFileUpload,
                                          //       },
                                          //     }));
                                          //   }
                                          // }
                                          />
                                        </div>
                                    
            </td>
           
          </>
        );
      }
      renderModalBody(selectedFormData) {
        return (
          <>
            <p className="mb-4">
            Description: <span className="text-primary">{selectedFormData.Description}</span>
            </p>
          </>
        );
      }
      render() {
        return (
          <div className="page-content">
          <RCDisplayPage
            //page header paramaters
            Isbreadcrumb = {true}
            breadCrumbTitle={this.breadCrumbTitle}
            breadcrumbItem={this.breadCrumbItem}
            obj={this.obj}
            //column paramaters
            isSearchBox={true}
            isSNo={true}
            isCheckBox={true}
            isViewDetails={false}
            //grid paramaters
            gridData={this.state.gridData}
            gridHeader={this.renderGridHeader}
            gridBody={this.renderGridBody}
            btnAdd_onClick={this.btnAdd_onClick}
            btnEdit_onClick={this.btnEdit_onClick}
            //delete link parameters
            confirm_alert={this.state.confirm_alert}
            success_dlg={this.state.success_dlg}
            dynamic_title={this.state.dynamic_title}
            dynamic_description={this.state.dynamic_description}
            toggleDeleteConfirm={toggleDeleteConfirm}
            toggleDeleteSuccess={toggleDeleteSuccess}
            btnDelete_onClick={this.btnDelete_onClick}
            //modal paramaters
            isOpenModal={this.state.modal}
            modalTitle={this.modalTitle}
            selectedFormData={this.state.selectedFormData}
            modalBody={this.renderModalBody}
            togglemodal={togglemodal}
            //user rights
            isAdd={true}
            isEdit2={false}
            isDelete={false}
          ></RCDisplayPage>
          </div>
        );
      }
    }
    export default compose(container)(pageAddEdit_SellBusiness);
    