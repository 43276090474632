import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledTooltip,
  Modal
} from "reactstrap";
import Switch from "react-switch";
import classnames from "classnames";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import Dropzone from "react-dropzone";
import { useSelector } from "react-redux";
// Redux
import { withRouter, Link } from "react-router-dom";

// Editable
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
// availity-reactstrap-validation
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import {
  Fn_DisplayData,
  Fn_AddEditData,
  Fn_FillListData,
  Fn_ChangeStateValue,
} from "../../store/functions";


export const DateString = date => {
  date = new Date(date);
  let yyyy = date.getFullYear();
  let mm = date.getMonth() + 1;
  let dd = date.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  let formattedToday = dd + "/" + mm + "/" + yyyy;
  return formattedToday;
};

class pageAdd_jobAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      IsFileUpload: true,
      formData: {},
      activeTab: 1,
      passedSteps: [1],
      BrowseImage1: "",
      BrowseImage2: "",
      UserImage: "",
      Signature: "",
      WorkMode: [
     
      ],
      RecruitmentType: [
        {
          Id: 1,
          Name: " Fixed Selection Date",
        },
        {
          Id: 2,
          Name: "Rolling Recruitment",
        },
        {
          Id: 3,
          Name: "Spot Recruitment",
        },
      ],
      //dropdowns
      gender: [],
      state_dd: [],
      Name: "",
      aid: 0,
      fo3: false,
      success_msg: false,
      Amount: 0,
      TimePeriod: 0,
      NoOfMembers: 0,
      InstallmentAmount: 0,
      StartDate: new Date().toString(),
      salaryclass: false,
      SalaryClassListMonthly: [],
      SalaryClassListAnnual: []
    };
    this.toggleTab.bind(this);
    this.obj = this;
    this.formTitle = "Job";
    this.breadCrumbTitle = "Job";
    this.breadCrumbItem = "Add " + this.formTitle;
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/GetJobList";
    this.API_URL_SAVE = "AddJobTitleByEntity/0/token";
    // this.pushFormName = "/countryMaster";
    // this.rtPage_City = "/countryMaster";

    //Event Binding
    this.btnSave_onClick = this.btnSave_onClick.bind(this);
    this.btnCancel_onClick = this.btnCancel_onClick.bind(this);
    this.syno = this.syno.bind(this);
    this.reset = this.reset.bind(this);
    this.oninfo = this.oninfo.bind(this);
    // this.onStateChange= this.onStateChange.bind(this);
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    Fn_FillListData(this.obj, "WorkMode", API_WEB_URLS.MASTER + "/0/token/DutyMode/Id/0");
    Fn_FillListData(
      this.obj,
      "SalaryClassListMonthly",
      API_WEB_URLS.MASTER + "/0/token/SalaryClass/F_Periodicity/1"
    );
    Fn_FillListData(
      this.obj,
      "SalaryClassListAnnual",
      API_WEB_URLS.MASTER + "/0/token/SalaryClass/F_Periodicity/2"
    );
    if (id) {
      this.setState({ id: id });
      this.breadCrumbItem = "Edit " + this.formTitle;

      Fn_DisplayData(this.obj, id, this.API_URL + "/Id");
    } else {
      this.setState({ id: 0 });
    }
  }



  oninfo() {
    this.setState({ salaryclass: true });
  }



  reset() {
    this.props.history.push(`/pagejoblist`, {});
  }
  // onStateChange(event){
  //   var F_StateMaster = event.target.value;
  //   Fn_FillListData(this.obj, "state_dd", API_WEB_URLS.MASTER + "/0/token/StateMaster/Id/"+F_StateMaster);
  // }
  async btnSave_onClick(event, formData) {
    let vformData = new FormData();
    //Information
    const obj = JSON.parse(localStorage.getItem("authUser"));

    vformData.append("F_MemberMaster", obj.uid);
    vformData.append("Name", formData.jobtitle);
    vformData.append("Grade", formData.Grade);
    vformData.append("JobDescription", formData.JobDescription);
    vformData.append("ClosingDate", formData.closingdate);
    vformData.append("WorkMode", formData.WorkMode);
    vformData.append("RecruitmentType", formData.F_RecruitmentType);
    vformData.append(
      "JobDescriptionFile.ImageFileName",
      this.state.JobDescriptionFile == undefined ? "ss" : "File"
    );
    vformData.append(
      "JobDescriptionFile.ImageFile",
      this.state.JobDescriptionFile
    );
    vformData.append(
      "IsFileUpload",
      this.state.formData.IsFileUpload == undefined
        ? this.state.IsFileUpload
        : this.state.formData.IsFileUpload
    );
    
    // vformData.append("F_StateMaster", 1);
    //create a post request for sending name and code using fetch method
    console.log(this.state.JobDescriptionFile);
    if (!this.state.id) {
      Fn_AddEditData(
        this.obj,
        { arguList: { id: 0, formData: vformData } },
        this.API_URL_SAVE,
        "/pagejoblist",
        true,
        "Id"
      );
    } else {
      // vformData.append("Id", this.state.id);
      Fn_AddEditData(
        this.obj,
        {
          arguList: {
            id: this.state.id,
            formData: vformData,
          },
        },
        this.API_URL_SAVE,
        "/pagejoblist",
        true,
        "Id"
      );
    }
  }

  syno() {
    this.setState({
      success_msg: false,
    });
    //event.preventDefault();
    this.props.history.push(`${this.rtPage_City}`, {});
  }

  btnCancel_onClick = event => {
    event.preventDefault();
    //this.props.history.goBack();
    this.props.history.push(this.pushFormName);
  };

  handleToggle() {
    //set state of isfileupload

    this.setState({
      IsFileUpload: !this.state.IsFileUpload,
    });

    
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        var modifiedSteps = [...this.state.passedSteps, tab];
        this.setState({
          activeTab: tab,
          passedSteps: modifiedSteps,
        });
      }
    }
  }

  render() {



    const {  SalaryClassListMonthly, salaryclass, SalaryClassListAnnual } = this.state;

    // Extract keys if SalaryClassListMonthly is not empty
    const keys = SalaryClassListMonthly.length > 0
      ? Object.keys(SalaryClassListMonthly[0]).filter(key => !isNaN(key))
      : [];

      const keysA = SalaryClassListAnnual.length > 0
      ? Object.keys(SalaryClassListAnnual[0]).filter(key => !isNaN(key))
      : [];
  

   

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.breadCrumbTitle}
              breadcrumbItem={this.breadCrumbItem}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="wizard clearfix">
                      <div className="steps clearfix"></div>
                      <div className="content clearfix">
                        <AvForm
                          className="needs-validation"
                          onValidSubmit={this.btnSave_onClick}
                        >
                          <TabContent
                            activeTab={this.state.activeTab}
                            className="body"
                          >
                            <TabPane tabId={1}>
                              <Row>
                                <Col lg="12">
                                  <Card>
                                    <CardBody>
                                      <h4 className="card-title mb-4"></h4>

                                      <Row>
                                        <Col sm="2" className="mb-3">
                                          <label
                                            htmlFor="Name"
                                            className="col-form-label"
                                          >
                                            Job Title
                                          </label>
                                        </Col>
                                        <Col sm="4">
                                          <AvField
                                            name="jobtitle"
                                            label=""
                                            value={
                                              this.state.formData.JobTitle ===
                                              null
                                                ? ""
                                                : this.state.formData.JobTitle
                                            }
                                            errorMessage="Enter Title "
                                            validate={{
                                              required: { value: true },
                                            }}
                                            placeholder="Enter  Name"
                                            type="text"
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row>
                                  
                                      <Row>
                                        <div className="form-check form-switch form-switch-lg mb-3">
                                          <label
                                            className="form-check-label"
                                            htmlFor="customSwitchsizelg"
                                          >
                                            {this.state.IsFileUpload
                                              ? "Upload Description File"
                                              : "Write Description"}
                                          </label>
                                          <input
                                            type="checkbox"
                                            className="form-check-input"
                                            checked={this.state.IsFileUpload}
                                            id="customSwitchsizelg"
                                            onChange={() => {
                                              this.setState(prevState => ({
                                                IsFileUpload:
                                                  !prevState.IsFileUpload,
                                                formData: {
                                                  ...prevState.formData,
                                                  IsFileUpload:
                                                    !prevState.IsFileUpload,
                                                },
                                              }));
                                            }}
                                          />
                                        </div>
                                      </Row>

                                      <Row>
                                        <Col sm="2" className="mb-3">
                                          <label
                                            htmlFor="Name"
                                            className="col-form-label"
                                          >
                                            Job Description
                                          </label>
                                        </Col>
                                        <Col sm="4">
                                          {this.state.IsFileUpload ? (
                                            <AvField
                                              name="JobDescriptionFile"
                                              label="CV"
                                              placeholder="Upload PDF"
                                              errorMessage="Upload PDF"
                                              onChange={e =>
                                                Fn_ChangeStateValue(
                                                  this.obj,
                                                  "JobDescriptionFile",
                                                  e.target.files[0]
                                                )
                                              }
                                              
                                              accept=".pdf"
                                              type="file"
                                              className="form-control"
                                            />
                                          ) : (
                                            <AvField
                                              name="JobDescription"
                                              label=""
                                              value={
                                                this.state.formData
                                                  .JobDescription || ""
                                              }
                                              placeholder="Enter Job Description"
                                              type="textarea"
                                              className="form-control"
                                            />
                                          )}
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="2">
                                          <label
                                            htmlFor="WorkMode"
                                            className="col-form-label"
                                          >
                                            WorkMode
                                          </label>
                                        </Col>
                                        <Col sm="4">
                                          <AvField
                                            name="WorkMode"
                                            label=""
                                            value={
                                              this.state.formData.WorkMode ===
                                              null
                                                ? ""
                                                : this.state.formData.WorkMode
                                            }
                                            errorMessage="Select  WorkMode"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            type="select"
                                            className="form-select"
                                          >
                                            <option
                                              value={-1}
                                              defaultValue
                                              label={"Select"}
                                            />
                                            {this.state.WorkMode
                                              ? this.state.WorkMode.map(
                                                  (option, key) => (
                                                    <option
                                                      key={option.Id}
                                                      value={option.Id}
                                                      label={option.Name}
                                                    />
                                                  )
                                                )
                                              : null}
                                          </AvField>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="2">
                                          <label
                                            htmlFor="Grade"
                                            className="col-form-label"
                                          >
                                            Grade
                                          </label>
                                        </Col>

                                        <Col lg="4">
                                          <AvField
                                            name="Grade"
                                            value={
                                              this.state.formData.Grade === null
                                                ? ""
                                                : this.state.formData.Grade
                                            }
                                            placeholder="Grade"
                                            errorMessage="Enter Grade "
                                            validate={{
                                              required: { value: true },
                                            }}
                                            type="text"
                                            className="form-control"
                                          />
                                        </Col>

                                              <Col  lg="2">
                                              <Link
                                    to={{
                                      state: { shop: '' },
                                    }}
                                    className="btn badge-soft-danger w-100"
                                    onClick={this.oninfo}
                                  >
                                    Pay-Scale
                                  </Link>
                                              </Col>

                                      </Row>
                                      {/* <Row>
                                        <Col sm="4" className="mb-3">
                                          <label
                                            htmlFor="PayScale"
                                            className="col-form-label"
                                          >
                                            PayScale
                                          </label>
                                        </Col>
                                        <Col sm="3">
                                          <AvField
                                            name="PayFrom"
                                            label=""
                                            value={
                                              this.state.formData.PayFrom ===
                                              null
                                                ? ""
                                                : this.state.formData.PayFrom
                                            }
                                            placeholder="from"
                                            type="text"
                                            className="form-control"
                                          />
                                        </Col>
                                        <Col
                                          sm="1"
                                          style={{ marginTop: "8px" }}
                                        >
                                          <span style={{ fontWeight: "bold" }}>
                                            -
                                          </span>
                                        </Col>
                                        <Col sm="3">
                                          <AvField
                                            name="PayTo"
                                            label=""
                                            value={
                                              this.state.formData.PayTo === null
                                                ? ""
                                                : this.state.formData.PayTo
                                            }
                                            placeholder="to"
                                            type="text"
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row> */}
                                      <Row>
                                        <Col sm="2">
                                          <label
                                            htmlFor="RecruitmentType"
                                            className="col-form-label"
                                          >
                                            Recruitment Type
                                          </label>
                                        </Col>
                                        <Col sm="4">
                                          <AvField
                                            name="F_RecruitmentType"
                                            label=""
                                            value={
                                              this.state.formData
                                                .RecruitmentType === null
                                                ? ""
                                                : this.state.formData
                                                    .RecruitmentType
                                            }
                                            errorMessage="Select  RecruitmentType"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            type="select"
                                            className="form-select"
                                          >
                                            <option
                                              value={-1}
                                              defaultValue
                                              label={"Select"}
                                            />
                                            {this.state.RecruitmentType
                                              ? this.state.RecruitmentType.map(
                                                  (option, key) => (
                                                    <option
                                                      key={option.Id}
                                                      value={option.Id}
                                                      label={option.Name}
                                                    />
                                                  )
                                                )
                                              : null}
                                          </AvField>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="2" className="mb-3">
                                          <label
                                            htmlFor="Name"
                                            className="col-form-label"
                                          >
                                            Closing Date
                                          </label>
                                        </Col>
                                        <Col sm="4">
                                          <AvField
                                            name="closingdate"
                                            label=""
                                            value={
                                              this.state.formData
                                                .ClosingDate === null
                                                ? ""
                                                : this.state.formData
                                                    .ClosingDate
                                            }
                                            errorMessage="Enter Closing Date "
                                            validate={{
                                              required: { value: true },
                                            }}
                                            placeholder="Enter  Name"
                                            type="date"
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row>
                                    </CardBody>
                                  </Card>
                                </Col>
                              </Row>
                              <Row></Row>
                            </TabPane>
                          </TabContent>

                          <div>
                            <Button
                              type="submit"
                              color="primary"
                              className="mr-1 waves-effect waves-light"
                            >
                              Save
                            </Button>

                            <Button
                              type="button"
                              color="primary"
                              style={{ marginLeft: "10px" }}
                              className="mr-1 waves-effect waves-light"
                              onClick={this.reset}
                            >
                              Cancel
                            </Button>

                            {this.state.success_msg ? (
                              <SweetAlert
                                title="Country Saved Successfully!"
                                success
                                confirmBtnBsStyle="success"
                                onConfirm={this.syno}
                              >
                                You clicked the button!
                              </SweetAlert>
                            ) : null}
                          </div>
                        </AvForm>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Modal
                  isOpen={salaryclass}
                  toggle={() => this.setState({ salaryclass: false })}
                  scrollable={true}
                  backdrop={'static'}
                  id="staticBackdrop2"
                  className="modal-fullscreen"
                >
                  <div className="modal-header">
                    <h5 className="modal-title">Salary Class</h5>
                    <button type="button" className="btn-close" onClick={() =>
                      this.setState({ salaryclass: false })
                    } aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                  <Row>
  <Col lg={12}>
    <Card className="compact-card">
      <CardBody className="compact-card-body">
        <CardTitle className="h4">Monthly Salary Rates</CardTitle>
        <div className="">
          <table className=" mb-0 compact-table">
            <thead>
              <tr>
                <th >Level</th>
                {keys.map(key => (
                  <th key={key} >Class {key}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {SalaryClassListMonthly.map((item, index) => (
                <tr key={item.Id} className={index % 2 === 0 ? 'table-light' : 'table-success'}>
                  <td>{item.Level}</td>
                  {keys.map(key => (
                    <td key={key} >{item[key]}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </CardBody>
    </Card>
  </Col>
</Row>

<Row>
  <Col lg={12}>
    <Card className="compact-card">
      <CardBody className="compact-card-body">
        <CardTitle className="h4">Annual Salary Rates</CardTitle>
        <div className="">
          <table className=" mb-0 compact-table">
            <thead>
              <tr>
                <th >Level</th>
                {keysA.map(key => (
                  <th key={key} >Class {key}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {SalaryClassListAnnual.map((item, index) => (
                <tr key={item.Id} className={index % 2 === 0 ? 'table-light' : 'table-success'}>
                  <td>{item.Level}</td>
                  {keysA.map(key => (
                    <td key={key} >{item[key]}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </CardBody>
    </Card>
  </Col>
</Row>


                  </div>
                  <div className="modal-footer"></div>
                </Modal>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default compose(container)(pageAdd_jobAdd);
