import PropTypes from 'prop-types';
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";


//i18n
import { withTranslation } from "react-i18next";

class NotificationDropdown extends Component {
  constructor(props) {
    super(props)
    this.state = {
      menu: false,
      noti : [
       
      // {
      //   id:6,
      //   message : 'You have something',
      //   time : '27/11/2023 10:42',
      //   page : 'register',
      //   head : 'Balance Add'
      // }
    ]
    }
    this.toggle = this.toggle.bind(this)
  }

  toggle() {
    this.setState(prevState => ({
      menu: !prevState.menu,
    }))
  }
  render() {
    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="dropdown d-inline-block"
          tag="li"
        >
          <DropdownToggle
            className="btn header-item noti-icon"
            tag="button"
            id="page-header-notifications-dropdown"
          >
            <i className="bx bx-bell bx-tada" />
            <span className="badge bg-danger rounded-pill">3</span>
          </DropdownToggle>

          <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
            <div className="p-3">
              <Row className="align-items-center">
                <Col>
                  <h6 className="m-0"> {this.props.t("Notifications")} </h6>
                </Col>
                <div className="col-auto">
                  <a href="#" className="small">
                    {" "}
                    clear All
                  </a>
                </div>
              </Row>
            </div>

            <SimpleBar style={{ height: "230px" }}>

         

                        {this.state.noti.map((value , index)=>(

                        <Link key={index} to={'/'+value.page} className="text-reset notification-item">
                        <div className="media">

                          <div className="media-body">
                            <h6 className="mt-0 mb-1">
                              {this.props.t(value.head)}
                            </h6>
                            <div className="font-size-12 text-muted">
                              <p className="mb-1">
                                {this.props.t(
                                  value.message
                                )}
                              </p>
                              <p className="mb-0">
                                <i className="mdi mdi-clock-outline" />{" "}
                                {value.time}{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                        </Link>

                        ))}

             
            
            </SimpleBar>
          
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    )
  }
}

NotificationDropdown.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(NotificationDropdown)
