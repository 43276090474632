import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import { Container, Card, CardBody, Col,  Row } from "reactstrap";
import { Link } from 'react-router-dom';
import CardShop from "./CardShop";

import { API_WEB_URLS } from "../../constants/constAPI";

import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import { Fn_FillListData } from "../../store/functions";

class selectJob extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Company: [
        
    ],
      colors: "white",
    };

    this.obj = this;
    this.API_URL_COMPANY = API_WEB_URLS.MASTER + "/0/token/GetJobList/Id/0";
    this.selectcompany = this.selectcompany.bind(this);
  }
  
  componentDidMount() {
    const obj = JSON.parse(localStorage.getItem("authUser"));
   

    Fn_FillListData(
      this.obj,
      "Company",
      this.API_URL_COMPANY 
    );
  }

  selectcompany(shop) {
    var LastId = localStorage.getItem("F_CompanyMaster");

    if (LastId != null) {
      var element2 = document.getElementById("div" + LastId);
      element2.style.backgroundColor = "white";
    }

    var element = document.getElementById("div" + shop.Id);
    element.style.backgroundColor = "rgb(204, 214, 245)";
    localStorage.setItem("F_CompanyMaster", shop.Id);
    localStorage.setItem("FirmName", shop.Name);
    alert("You Selected - " + shop.Name);
    
  }



  render() {
    const shop = {
      name: "lk",
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Dashboard | Sahakaar Admin & Dashboard</title>
          </MetaTags>
          <Container fluid>
            <h4>Jobs</h4>

            <Row>
              {this.state.Company
                ? this.state.Company.map((item, key) => (
                    // <CardShop
                    //   color="black"
                    //   shop={option}
                    //   key={"_shop_" + key}
                    //   selectcompany={this.selectcompany}
                    //   colors={this.state.colors}
                    // />




                    <Col xl={3} md={6} style={{width:'16%'}} key={key}>
                                    <Card style={{height:'91%'}}>
                                        <CardBody>
                                            <div className="favorite-icon">
                                                <Link to="#"><i className="uil uil-heart-alt fs-18"></i></Link>
                                            </div>
                                            <img src={"https://api.societiesbank.com/MemberImages/" + 
                          item.EntityLogo} alt="" height="50" className="mb-3" />
                                            <h5 className="fs-17 mb-2" style={{height:'38px'}}>
                                                <Link to="/job-details" className="text-dark">{item.JobTitle}</Link> 
                                                </h5>
                                            <ul className="list-inline mb-0" style={{height:'124px'}}>
                                                <li className="list-inline-item">
                                                <h5 className="fs-17 mb-2">
                                                    <p className="text-muted fs-14 mb-1">{item.Organization}</p>
                                                  </h5>
                                                </li>{" "}
                                                <ul>
                                               </ul>
                                              
                                               
                                                <li className="list-inline-item" >
                                                    <p className="text-muted fs-14 mb-0 ml-0" style={{color:'black'}} >Grade :  {item.Grade}</p>
                                                </li>
                                            </ul>
                                            <div className="mt-3 hstack gap-2">
                                                <span className="badge rounded-1 badge-soft-success">Full Time</span>
                                                <span className="badge rounded-1 badge-soft-warning">Urgent</span>
                                                <span className="badge rounded-1 badge-soft-info">Private</span>
                                            </div>
                                            <div className="mt-4 hstack gap-2">
                                                <Link to="/job-details" className="btn badge-soft-success w-100">View Profile</Link>
                                                <Link  to={{
                  pathname: "/jobapplication",
                  state: { shop: item },
                }}  className="btn badge-soft-info w-100">Apply Now</Link>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                  ))
                : null}
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default compose(container)(selectJob);
