import PropTypes from "prop-types";
import React, { Component } from "react";

//Simple bar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.refDiv = React.createRef();
    this.state = {
      roleid: 0
    };
  }

  componentDidMount() {
    this.initMenu();
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, ss) {
    if (this.props.type !== prevProps.type) {
      this.initMenu();
    }
  }

  initMenu() {
    new MetisMenu("#side-menu");

    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  // componentDidUpdate() {}

  scrollElement = item => {
    setTimeout(() => {
      if (this.refDiv.current !== null) {
        if (item) {
          const currentPosition = item.offsetTop;
          if (currentPosition > window.innerHeight) {
            if (this.refDiv.current)
              this.refDiv.current.getScrollElement().scrollTop =
                currentPosition - 300;
          }
        }
      }
    }, 300);
  };

  activateParentDropdown = item => {
    item.classList.add("active");
    const parent = item.parentElement;

    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      this.scrollElement(item);
      return false;
    }
    this.scrollElement(item);
    return false;
  };

  render() {

    const obj = JSON.parse(localStorage.getItem("authUser"));
 console.log('role',obj.roleid);
if (obj.roleid == 0){
  return null;
}

//Admin

   
else if (obj.roleid == 2) {

    return (
      <React.Fragment>
        <SimpleBar autoHide={false}   className="h-100"  ref={this.refDiv}>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li className="menu-title">{this.props.t("Menu")}</li>
              <li>
                <Link to="/#">
                  <i className="bx bx-home-circle" />
                  <span className="badge rounded-pill bg-info float-end">
                    04
                  </span>
                  <span>{this.props.t("College Desktop")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/dashboard">{this.props.t("Default")}</Link>
                  </li>
                
                </ul>
              </li>

              <li className="menu-title">{this.props.t("Apps")}</li>
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-store" />
                  <span>{this.props.t("Create")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                <li>
                    <Link to="/masters-CourseMaster">
                      {this.props.t("Course")}
                    </Link>
                  </li>
                   <li>
                    <Link to="/masters-citymaster">
                      {this.props.t("City ")}
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/masters-TypeMaster">
                      {this.props.t("Type")}
                    </Link>
                  </li> */}
                  <li>
                    <Link to="/masters-PartnerMaster">
                      {this.props.t("Partner View")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/CourseAndYear">
                      {this.props.t("Create Course & Year")}
                    </Link>
                  </li>

                  <li>
                    <Link to="/masters-TeamLeader">
                      {this.props.t("Create Team Leader")}
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/masters-StudentMaster">
                      {this.props.t("Approve Admission")}
                    </Link>
                  </li> */}
                </ul>
              </li>

              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-store" />
                  <span>{this.props.t("Admission File")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
              
                  <li>
                    <Link to="/CourseRunAndStop">
                      {this.props.t("Admission Course Open & Close List")}
                    </Link>
                  </li>
                 
                 
                  <li>
                    <Link to="/masters-StudentMaster">
                      {this.props.t("Admission Verify By College")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/admissiondonelist">
                      {this.props.t("View Admission Done List")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/admissionnotdonelist">
                      {this.props.t("View Admission Not Done List")}
                    </Link>
                  </li>

                   <li>
                    <Link to="/verifyamount">
                      {this.props.t("Set Payment")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/PaymentSentAndViewLedger">
                      {this.props.t("Payment Sent & View Ledger")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/listofallteam">
                      {this.props.t("List Of All Team")}
                    </Link>
                  </li>
                </ul>
              </li>
              
              <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-receipt" />
                <span>{this.props.t("Online Class")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">

             
                <li>
                  <Link to="masters-onlineclass">
                    {this.props.t("Add Video")}
                  </Link>
                </li>

                <li>
                  <Link to="view-video">
                    {this.props.t("Class Video")}
                  </Link>
                </li>
               
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-receipt" />
                <span>{this.props.t("Librabry")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">

                <li>
                  <Link to="coursemapreport">
                    {this.props.t("Student Course MAP Report")}
                  </Link>
                </li>
               
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-receipt" />
                <span>{this.props.t("Store/Stock Register")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">

                <li>
                  <Link to="coursemapreport">
                    {this.props.t("Student Course MAP Report")}
                  </Link>
                </li>
               
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-receipt" />
                <span>{this.props.t("Student Fees")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">

                <li>
                  <Link to="coursemapreport">
                    {this.props.t("Student Course MAP Report")}
                  </Link>
                </li>
               
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-receipt" />
                <span>{this.props.t("Teaching Staff")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">

                <li>
                  <Link to="coursemapreport">
                    {this.props.t("Student Course MAP Report")}
                  </Link>
                </li>
               
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-receipt" />
                <span>{this.props.t("Student Bio-Data")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">

                <li>
                  <Link to="coursemapreport">
                    {this.props.t("Student Course MAP Report")}
                  </Link>
                </li>
               
              </ul>
            </li>



              <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-receipt" />
                <span>{this.props.t("Reports")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">

                <li>
                  <Link to="coursemapreport">
                    {this.props.t("Student Course MAP Report")}
                  </Link>
                </li>
               
              </ul>
            </li>

              {/* <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-receipt" />
                  <span>{this.props.t("Help")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="#">{this.props.t("User Help")}</Link>
                  </li>
                </ul>
              </li> */}


              
            </ul>
          </div>
        </SimpleBar>
      </React.Fragment>
    );

}




//Customer


else if (obj.roleid == 2) {



  return (
    <React.Fragment>
      <SimpleBar autoHide={false}   className="h-100"  ref={this.refDiv}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{this.props.t("Menu")}</li>
            <li>
              <Link to="/#">
                <i className="bx bx-home-circle" />
                <span className="badge rounded-pill bg-info float-end">
                  04
                </span>
                <span>{this.props.t("Dashboards")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/dashboard">{this.props.t("Default")}</Link>
                </li>
              
              </ul>
            </li>

            <li className="menu-title">{this.props.t("Apps")}</li>
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-store" />
                <span>{this.props.t("Masters")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                
              
              
                 
                  {/* <li>
                    <Link to="editemp-shiftmaster">
                      {this.props.t("Emp Shift Edit")}
                    </Link>
                  </li> */}
              </ul>
            </li>
           



            {/* <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-receipt" />
                <span>{this.props.t("Reports")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="daywiseatt">
                    {this.props.t("Day Wise Att.")}
                  </Link>
                </li>
               
              </ul>
            </li> */}


          
          
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-receipt" />
                <span>{this.props.t("Help")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="#">{this.props.t("User Help")}</Link>
                </li>

               
               
              </ul>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );

}




  }
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  type: PropTypes.string,
};

export default withRouter(withTranslation()(SidebarContent));
