/* eslint-disable */


import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal
} from "reactstrap";

import classnames from "classnames";
import Select from "react-select";
import Dropzone from "react-dropzone";
import { useSelector } from "react-redux";
// Redux
import { withRouter, Link } from "react-router-dom";
// availity-reactstrap-validation
import {
  AvForm,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
import SweetAlert from "react-bootstrap-sweetalert"
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import { Fn_DisplayData, Fn_AddEditData, Fn_FillListData, Fn_GetReport, Fn_ChangeStateValue } from "../../store/functions";
import axios from "axios";

function getdate() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');

  return  `${year}-${month}-${day}`;

}




class pageAddEdit_JobApplication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      formData: {},
      activeTab: 1,
      passedSteps: [1],
      productData: [],
      MemberDetails : [{}], 
      selectedFiles: [],
      error: ''

    };

  
  
    this.toggleTab.bind(this);
    this.obj = this;
    this.formTitle = "Application for Employment ";
    this.breadCrumbTitle = "Application for Employment ";
    this.breadCrumbItem =  this.formTitle;
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/Membership";
    this.API_URL_SAVE = "EmployeeMaster/0/token";
    this.pushFormName = "/dashboard";
  

    //Event Binding
    this.btnSave_onClick = this.btnSave_onClick.bind(this);
    this.btnCancel_onClick = this.btnCancel_onClick.bind(this);
    this.syno =  this.syno.bind(this);
    
   
    this.btnReset_onClick  =  this.btnReset_onClick.bind(this);
   
   

  }




  componentDidMount() {
    //Filling DropDowns
    
    const obj = JSON.parse(localStorage.getItem("authUser"));
    
    Fn_FillListData(this.obj, "MemberDetails", API_WEB_URLS.MASTER + "/0/token/GetMemberDetailsForForm/Id/"+obj.uid);

    Fn_FillListData(this.obj, "DutyMode", API_WEB_URLS.MASTER + "/0/token/DutyMode/Id/0");
    Fn_FillListData(this.obj, "Designation", API_WEB_URLS.MASTER + "/0/token/GetDesignation/Id/2");
    Fn_FillListData(this.obj, "Organisation", API_WEB_URLS.MASTER + "/0/token/Organisation/Id/0");


    const { id } = this.props.match.params;
    if (id) {
      this.setState({ id: id });
      this.breadCrumbItem = "Edit " + this.formTitle;
      Fn_DisplayData(this.obj, id, this.API_URL + "/Id");
    } else {
      this.setState({ id: 0 });
    }
  }

  fun() {

  }



  btnSave_onClick = async(event, formData)=> {

    const obj = JSON.parse(localStorage.getItem("authUser"));
    
    let vformData = new FormData();
    //Information
    vformData.append("F_MemberMaster", obj.uid);
    
    vformData.append(
      "F_JobTitleOrganization",
      this.props.location.state.shop.Id
    );
    
    vformData.append("F_DutyMaster", formData.F_DutyMode);


    vformData.append("FullIMG.ImageFileName", "SS");
    vformData.append("FullIMG.ImageFile", this.state.FullIMG);
    vformData.append("PassportIMG.ImageFileName", "SS");
    vformData.append("PassportIMG.ImageFile", this.state.PassportIMG);
    vformData.append("CV.ImageFileName", "SS");
    vformData.append("CV.ImageFile", this.state.CV);

    Fn_AddEditData(this.obj, { arguList: { id: 0, formData: vformData } }, this.API_URL_SAVE, "#", true , "memberid", this.fun);
    
    this.props.history.push(`/dashboard`, {});
    
  }



  btnCancel_onClick = event => {
    event.preventDefault();
    //this.props.history.goBack();
    this.props.history.push(this.pushFormName);
  };

  
  btnReset_onClick = event => {
    this.setState({
      Balance : [{
        Balance : 0
       }],
      MemberName : [
  {
    Name : ''
  }
      ],
      MembershipNo : ''
    });
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        var modifiedSteps = [...this.state.passedSteps, tab];
        this.setState({
          activeTab: tab,
          passedSteps: modifiedSteps,
        });
        
      }
    }
  }







  syno () {

    this.setState({
      success_msg : false
    });

    this.props.history.push(this.pushFormName);

      };



      handleFileChange = (e) => {
        const files = e.target.files;
       
        if (files.length <= 5) {
            this.setState({
                selectedFiles: files,
                error: ''
            });
            Fn_ChangeStateValue(this.obj, 'Certificates', files);
        } else {
          alert('You can select up to 5 files.')
          setTimeout(() => {
            e.target.value = null; // Clear the selected files
        }, 0);
        }
    };





 




  render() {
    
   

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.breadCrumbTitle}
              breadcrumbItem={this.breadCrumbItem}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="wizard clearfix">
                      <div className="content clearfix">
                        <AvForm
                          className="needs-validation"
                          onValidSubmit={this.btnSave_onClick}
                        >
                          <Row>
                            <Col lg="12">
                              <Card>
                                <CardBody>
                                  <Row>
                                    <Col sm="2">
                                      <label
                                        htmlFor="membershipType"
                                        className="col-form-label"
                                      >
                                        Member GIN
                                      </label>
                                    </Col>
                                    <Col lg="4">
                                      <AvField
                                        name="MembershipNo"
                                        value={
                                          this.state.MemberDetails[0]
                                            .MembershipNo
                                        }
                                        placeholder="Member GIN"
                                        type="text"
                                        disabled
                                        className="form-control"
                                      />
                                    </Col>

                                    <Col sm="2">
                                      <label
                                        htmlFor="membershipType"
                                        className="col-form-label"
                                      >
                                        {" "}
                                        Name
                                      </label>
                                    </Col>
                                    <Col sm="4">
                                      <AvField
                                        name="Name"
                                        value={
                                          this.state.MemberDetails[0].FullName
                                        }
                                        placeholder=" Name"
                                        type="text"
                                        disabled
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col sm="2">
                                      <label
                                        htmlFor="membershipType"
                                        className="col-form-label"
                                      >
                                        {" "}
                                        Address
                                      </label>
                                    </Col>
                                    <Col sm="4">
                                      <AvField
                                        name="Address"
                                        value={
                                          this.state.MemberDetails[0]
                                            .Address_Residential
                                        }
                                        placeholder="Address"
                                        type="text"
                                        disabled
                                        className="form-control"
                                      />
                                    </Col>

                                    <Col sm="2">
                                      <label
                                        htmlFor="membershipType"
                                        className="col-form-label"
                                      >
                                        Nationality
                                      </label>
                                    </Col>
                                    <Col sm="4">
                                      <AvField
                                        name="Nationality"
                                        label=""
                                        value={
                                          this.state.MemberDetails[0]
                                            .Nationality
                                        }
                                        placeholder="Nationality"
                                        type="text"
                                        className="form-control"
                                        disabled
                                      ></AvField>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col sm="2">
                                      <label
                                        htmlFor="membershipType"
                                        className="col-form-label"
                                      >
                                        Email Address
                                      </label>
                                    </Col>
                                    <Col sm="4">
                                      <AvField
                                        name="Email"
                                        label=""
                                        value={
                                          this.state.MemberDetails[0].Email
                                        }
                                        placeholder="Email"
                                        type="text"
                                        className="form-control"
                                        disabled
                                      ></AvField>
                                    </Col>

                                    <Col sm="2">
                                      <label
                                        htmlFor="membershipType"
                                        className="col-form-label"
                                      >
                                        Phone Number
                                      </label>
                                    </Col>
                                    <Col sm="4">
                                      <AvField
                                        name="PhoneNo"
                                        label=""
                                        value={
                                          this.state.MemberDetails[0].Phone
                                        }
                                        placeholder="PhoneNo"
                                        type="text"
                                        className="form-control"
                                        disabled
                                      ></AvField>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col sm="2">
                                      <label
                                        htmlFor="membershipType"
                                        className="col-form-label"
                                      >
                                        Gender
                                      </label>
                                    </Col>
                                    <Col sm="4">
                                      <AvField
                                        name="Gender"
                                        label=""
                                        value={
                                          this.state.MemberDetails[0].Gender
                                        }
                                        placeholder="Gender"
                                        type="text"
                                        disabled
                                        className="form-control"
                                      ></AvField>
                                    </Col>

                                    <Col sm="2">
                                      <label
                                        htmlFor="membershipType"
                                        className="col-form-label"
                                      >
                                        Age
                                      </label>
                                    </Col>
                                    <Col sm="4">
                                      <AvField
                                        name="Age"
                                        label=""
                                        value={this.state.MemberDetails[0].Age}
                                        placeholder="DOB"
                                        type="text"
                                        className="form-control"
                                        disabled
                                      ></AvField>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col sm="2">
                                      <label
                                        htmlFor="membershipType"
                                        className="col-form-label"
                                      >
                                        {" "}
                                        Organisation
                                      </label>
                                    </Col>
                                    <Col sm="4">
                                      <AvField
                                        name="F_OrganisationMaster"
                                        value={
                                          this.props.location.state.shop &&
                                          this.props.location.state.shop
                                            .Organization
                                        }
                                        placeholder="Organization"
                                        type="text"
                                        disabled
                                        className="form-control"
                                      />
                                    </Col>

                                    <Col sm="2">
                                      <label
                                        htmlFor="membershipType"
                                        className="col-form-label"
                                      >
                                        {" "}
                                        Designation
                                      </label>
                                    </Col>
                                    <Col sm="4">
                                      <AvField
                                        name="F_DesignationMaster"
                                        value={
                                          this.props.location.state.shop &&
                                          this.props.location.state.shop
                                            .JobTitle
                                        }
                                        placeholder="JobTitle"
                                        type="text"
                                        disabled
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col sm="2" className="mb-3">
                                      <label
                                        htmlFor="bloodGroup"
                                        className="col-form-label"
                                      >
                                        Duty Mode
                                      </label>
                                      <label style={{ color: "red" }}>*</label>
                                    </Col>
                                    <Col sm="4">
                                      <AvField
                                        name="F_DutyMode"
                                        label=""
                                        value={
                                          this.state.formData.F_DutyMode ===
                                          null
                                            ? "-1"
                                            : this.state.formData.F_DutyMode
                                        }
                                        errorMessage="Select Duty Mode"
                                        validate={{ required: { value: true } }}
                                        type="select"
                                        className="form-select"
                                      >
                                        <option
                                          value={-1}
                                          defaultValue
                                          label={"Select"}
                                        />
                                        {this.state.DutyMode
                                          ? this.state.DutyMode.map(
                                              (option, key) => (
                                                <option
                                                  key={option.Id}
                                                  value={option.Id}
                                                  label={option.Name}
                                                />
                                              )
                                            )
                                          : null}
                                      </AvField>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col lg="6">
                                      <AvField
                                        name="CV"
                                        label="CV"
                                        placeholder="Upload PDF"
                                        errorMessage="Upload PDF"
                                        onChange={e =>
                                          Fn_ChangeStateValue(
                                            this.obj,
                                            "CV",
                                            e.target.files[0]
                                          )
                                        }
                                        accept=".pdf"
                                        type="file"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col lg="6">
                                      <AvField
                                        name="FullIMG"
                                        label="Full Image"
                                        placeholder="Upload FullIMG"
                                        errorMessage="Upload FullIMG"
                                        onChange={e =>
                                          Fn_ChangeStateValue(
                                            this.obj,
                                            "FullIMG",
                                            e.target.files[0]
                                          )
                                        }
                                        accept=".jpg,.jpeg,.png"
                                        type="file"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col lg="6">
                                      <AvField
                                        name="PassportIMG"
                                        label="Passport Image"
                                        placeholder="Upload Passport Image"
                                        errorMessage="Upload Passport Image"
                                        onChange={e =>
                                          Fn_ChangeStateValue(
                                            this.obj,
                                            "PassportIMG",
                                            e.target.files[0]
                                          )
                                        }
                                        accept=".jpg,.jpeg,.png"
                                        type="file"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col lg="6">
                                      <AvField
                                        name="Certificates"
                                        label="Certificates (select upto 5 files)"
                                        placeholder="Upload Certificates"
                                        errorMessage={this.state.error}
                                        onChange={this.handleFileChange}
                                        accept=".pdf"
                                        type="file"
                                        className="form-control"
                                        multiple
                                      />
                                    </Col>
                                  </Row>

                                  <div className="d-flex flex-wrap gap-2">
                                    <Button type="submit" color="primary">
                                      Submit
                                    </Button>{" "}
                                    {this.state.success_msg ? (
                                      <SweetAlert
                                        title="Job Application Submitted Successful!"
                                        success
                                        confirmBtnBsStyle="success"
                                        onConfirm={this.syno}
                                      ></SweetAlert>
                                    ) : null}{" "}
                                    <Button
                                      type="button"
                                      color="secondary"
                                      className="waves-effect"
                                      onClick={this.btnCancel_onClick}
                                    >
                                      Exit
                                    </Button>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </AvForm>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default compose(container)(pageAddEdit_JobApplication);
