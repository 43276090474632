import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Button,
  Modal,
  Table
} from "reactstrap";
import { Link } from 'react-router-dom';
import CardShop from "./CardShop";

import { API_WEB_URLS } from "../../constants/constAPI";

import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import { Fn_FillListData } from "../../store/functions";
import './datatables.scss';


class NewJobList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Company: [],
      filterOptions: [
        { Id: 1, Name: 'HealthCare' },
        { Id: 2, Name: 'Construction' },
        { Id: 3, Name: 'Aviation' },
        { Id: 4, Name: 'Management' },
        { Id: 5, Name: 'Sales and Marketing' },
        { Id: 6, Name: 'Education' },
        { Id: 7, Name: 'Social Service' },
      ],
      colors: "white",
      salaryclass: false,
      SalaryClassListMonthly: [],
      SalaryClassListAnnual: []
    };

    this.obj = this;
    this.API_URL_COMPANY = API_WEB_URLS.MASTER + "/0/token/GetJobList/Id/0";
    this.selectcompany = this.selectcompany.bind(this);
    this.onSelectDiscount = this.onSelectDiscount.bind(this);
    this.oninfo = this.oninfo.bind(this);
  }

  componentDidMount() {
    const obj = JSON.parse(localStorage.getItem("authUser"));

    Fn_FillListData(
      this.obj,
      "Company",
      this.API_URL_COMPANY
    );

    Fn_FillListData(
      this.obj,
      "SalaryClassListMonthly",
      API_WEB_URLS.MASTER + "/0/token/SalaryClass/F_Periodicity/1"
    );
    Fn_FillListData(
      this.obj,
      "SalaryClassListAnnual",
      API_WEB_URLS.MASTER + "/0/token/SalaryClass/F_Periodicity/2"
    );
  }

  selectcompany(shop) {
    var LastId = localStorage.getItem("F_CompanyMaster");

    if (LastId != null) {
      var element2 = document.getElementById("div" + LastId);
      element2.style.backgroundColor = "white";
    }

    var element = document.getElementById("div" + shop.Id);
    element.style.backgroundColor = "rgb(204, 214, 245)";
    localStorage.setItem("F_CompanyMaster", shop.Id);
    localStorage.setItem("FirmName", shop.Name);
    alert("You Selected - " + shop.Name);
  }

  onSelectDiscount() { }

  oninfo() {
    this.setState({ salaryclass: true });
  }

  render() {
    const { filterOptions, SalaryClassListMonthly, salaryclass, SalaryClassListAnnual } = this.state;

    // Extract keys if SalaryClassListMonthly is not empty
    const keys = SalaryClassListMonthly.length > 0
      ? Object.keys(SalaryClassListMonthly[0]).filter(key => !isNaN(key))
      : [];

      const keysA = SalaryClassListAnnual.length > 0
      ? Object.keys(SalaryClassListAnnual[0]).filter(key => !isNaN(key))
      : [];

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Dashboard | Sahakaar Admin & Dashboard</title>
          </MetaTags>
          <Container fluid>
            <h4>Jobs</h4>
            <Row>
              <Col lg="3">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Filter</CardTitle>
                    <div className="mt-4 pt-3">
                      {filterOptions.map((discount, i) => (
                        <div className="form-check mt-2" key={i}>
                          <Input
                            type="checkbox"
                            value={discount.Id}
                            className="form-check-input"
                            id={i}
                            onChange={this.onSelectDiscount}
                          />{" "}
                          <Label className="form-check-label" htmlFor={i}>
                            {discount.Name}
                          </Label>
                        </div>
                      ))}
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col lg="9">
                <Row className="mb-3">
                  <Col xl="4" sm="6">
                    <div className="mt-1">
                      <h5>Jobs</h5>
                    </div>
                  </Col>
                </Row>
                <Row>
                  {this.state.Company
                    ? this.state.Company.map((item, key) => (
                      <Col xl="12" sm="6" key={"_col_" + key}>
                        <Card>
                          <CardBody style={{ paddingTop: "0.25rem", paddingBottom: "0.25rem" }}>
                            <Row className="align-items-center">
                              <div className="mt-1 col">
                                <h4 className="mb-1 text-truncate">
                                  <Link className="text-dark">{item.JobTitle}</Link>
                                </h4>
                                <h5>
                                  <div className="text mb-1">
                                    {item.Organization} <br></br>
                                    {item.Country}
                                  </div>
                                </h5>
                                <div className="text " style={{display:"flex"}}><h6 style={{marginRight:8, marginTop:2}}>Duty Type: </h6>   {item.DutyType}</div>
                                <div className="text " style={{display:"flex"}}><h6 style={{marginRight:8, marginTop:2}}>Salary Scale:</h6> {item.Grade}
                                  {/* <svg onClick={this.oninfo} style={{ marginLeft: 10 }} xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 16 16">
                                    <path fill="#27a7d8" d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412l-1 4.705c-.07.34.029.533.304.533c.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598c-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081l.082-.381l2.29-.287zM8 5.5a1 1 0 1 1 0-2a1 1 0 0 1 0 2" />
                                  </svg> */}
                                </div>
                                <div className="text " style={{display:"flex"}}><h6 style={{marginRight:8, marginTop:2}}>Expires: </h6> {item.ClosingDateView}</div>
                                <div></div>
                              </div>
                              <div className="col-auto d-flex flex-column">
                              <Link
                                    to={{
                                      state: { shop: item },
                                    }}
                                    className="btn badge-soft-danger w-100"
                                    onClick={this.oninfo}
                                  >
                                    Pay-Scale
                                  </Link>

                                <Link to="/job-details" style={{ marginTop: "10px" }} className="btn badge-soft-success w-100">Job Summary</Link>
                                <Link to={{
                                  pathname: "/jobapplication",
                                  state: { shop: item },
                                }} className="btn badge-soft-info w-100" style={{ marginTop: "10px" }}>Apply Now</Link>
                     
                              </div>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    ))
                    : null}
                </Row>

                <Modal
                  isOpen={salaryclass}
                  toggle={() => this.setState({ salaryclass: false })}
                  scrollable={true}
                  backdrop={'static'}
                  id="staticBackdrop2"
                  className="modal-fullscreen"
                >
                  <div className="modal-header">
                    <h5 className="modal-title">Salary Class</h5>
                    <button type="button" className="btn-close" onClick={() =>
                      this.setState({ salaryclass: false })
                    } aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                  <Row>
  <Col lg={12}>
    <Card className="compact-card">
      <CardBody className="compact-card-body">
        <CardTitle className="h4">Monthly Salary Rates</CardTitle>
        <div className="">
          <table className=" mb-0 compact-table">
            <thead>
              <tr>
                <th >Level</th>
                {keys.map(key => (
                  <th key={key} >Class {key}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {SalaryClassListMonthly.map((item, index) => (
                <tr key={item.Id} className={index % 2 === 0 ? 'table-light' : 'table-success'}>
                  <td>{item.Level}</td>
                  {keys.map(key => (
                    <td key={key} >{item[key]}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </CardBody>
    </Card>
  </Col>
</Row>

<Row>
  <Col lg={12}>
    <Card className="compact-card">
      <CardBody className="compact-card-body">
        <CardTitle className="h4">Annual Salary Rates</CardTitle>
        <div className="">
          <table className=" mb-0 compact-table">
            <thead>
              <tr>
                <th >Level</th>
                {keysA.map(key => (
                  <th key={key} >Class {key}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {SalaryClassListAnnual.map((item, index) => (
                <tr key={item.Id} className={index % 2 === 0 ? 'table-light' : 'table-success'}>
                  <td>{item.Level}</td>
                  {keysA.map(key => (
                    <td key={key} >{item[key]}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </CardBody>
    </Card>
  </Col>
</Row>


                  </div>
                  <div className="modal-footer"></div>
                </Modal>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default compose(container)(NewJobList);
