/* eslint-disable */
import React, { Component } from "react";

//Import Breadcrumb
import RCDisplayPage from "../../components/Common/RCDisplayPage";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import {
  Fn_FillListData,
  Fn_ChangeStateValue
} from "../../store/functions";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  AvForm,
  AvField,AvRadioGroup,AvRadio
} from "availity-reactstrap-validation";
import { reset } from "redux-form";

class pageReport_MemberStatement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      selectedFormData: {},
      gridData: [],
      confirm_alert: false,
      success_dlg: false,
      dynamic_title: "",
      Accountdetail: [
        {
          Name: ""
        }
      ],
      formData : {},
      dynamic_description: "",
      TimePeriod : 0,
      MemberId : 0,
      show : false,
      BoldToken : [{
        Token : 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImluZm9Ac2hpbmV3ZWxsaW5ub3ZhdGlvbi5jb20iLCJuYW1laWQiOiIxIiwidW5pcXVlX25hbWUiOiIzMTZiYmI3Ny1kOWIxLTQwNWYtYWRiYy01YzYyN2I0M2YwOWQiLCJJUCI6IjQ5LjQzLjE3OS4xMjEiLCJpc3N1ZWRfZGF0ZSI6IjE2ODM5NTcyNTAiLCJuYmYiOjE2ODM5NTcyNTAsImV4cCI6MTY4NDU2MjA1MCwiaWF0IjoxNjgzOTU3MjUwLCJpc3MiOiJodHRwOi8vc2hpbmV3ZWxsc29mdHRlY2guY28uaW46NTA2OTMvcmVwb3J0aW5nL3NpdGUvc2l0ZTEiLCJhdWQiOiJodHRwOi8vc2hpbmV3ZWxsc29mdHRlY2guY28uaW46NTA2OTMvcmVwb3J0aW5nL3NpdGUvc2l0ZTEifQ.0xTKNervvVkvTB0HkijKZnKpR9Oi8A52IY9hnTik6ew'
      }],
      VoucherId : 411
  
    };
    this.obj = this;
    this.PageName =  "MEMBER STATEMENT"
    this.breadCrumbTitle = "Member Statement Report";
    this.breadCrumbItem = "Member Statement Report";
    this.btnSave_onClick  = this.btnSave_onClick.bind(this);
    this.reset = this.reset.bind(this);

    //Event Binding

  }
  componentDidMount() {

    localStorage.removeItem("PageName");
    localStorage.setItem("PageName", this.PageName);
    Fn_FillListData(this.obj, "BoldToken", API_WEB_URLS.MASTER + "/0/token/BoldToken/Id/0");
  }

  
  getmember =  (event) => {
    var value = event.target.value;

    Fn_FillListData(this.obj, "Accountdetail", API_WEB_URLS.MASTER + "/0/token/GetBalance/1/Mem/Balance?ListFor=" + value + "&F_AccountType=11");

    } 

    btnSave_onClick () {
      this.setState({show : true})
    }

    reset () {
      this.setState({show : false , formData:{
        time : -1,
        txtSearch : '',
        MemberId : -1
      },
      Accountdetail : [{
        Name : ''
      }],
      
    })
      
    }

  
  
  render() {

    const obj = JSON.parse(localStorage.getItem("authUser"));

    var parameters = [{
      name: 'Id',
      values: [obj.uid]
      },
      {
        name: 'TimePeriod',
        values: [this.state.TimePeriod]
      }
    ];

    return (

<>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.breadCrumbTitle}
              breadcrumbItem={this.breadCrumbItem}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                   
                    <div className="wizard clearfix">
                      <div className="content clearfix">
                            
                            <AvForm className="needs-validation" onValidSubmit={this.btnSave_onClick}>
                         
                                <Row>
                                  <Col lg="9">
                                    <Row>
                                    <Col sm="2" className="mb-3">
                                          <label htmlFor="DateofBirth" className="col-form-label">Select Time</label>
                                        </Col>
                                    <Col sm="3" className="mb-3">
                                    <AvField name="time" label="" value={this.state.formData.MemberId} onChange={(e) => Fn_ChangeStateValue(this.obj, 'TimePeriod', e.target.value)}  type="select" className="form-select" >
                                    <option value={0} defaultValue label={"Select"} />
                                            <option value={4} defaultValue label={"Current Year"} />
                                            <option value={5} defaultValue label={"Previous Year"} />
                                            <option value={1} defaultValue label={"Last 30 Days"} />
                                            <option value={2} defaultValue label={"Last 90 Days"} />
                                            <option value={3} defaultValue label={"Last 180 Days"} />
                                          </AvField>
                                          </Col>
                                      </Row>

                                      {/* <Row>
                                      <Col sm="2" className="mb-3">
                                          <label htmlFor="DateofBirth" className="col-form-label">Search Member</label>
                                        </Col>
                                        <Col sm="3" className="mb-3">
                                        <AvField name="txtSearch" onChange={this.getmember} label=""  value={this.state.formData.txtSearch} placeholder="Search"  type="text"  className="form-control" />
                                        </Col>
                                        <Col sm="2">
                                      <label htmlFor="membershipType" className="col-form-label">A/C Holder Name</label>
                                    </Col>
                                    <Col lg="4">

                                      <AvField name="accountholdername" value={this.state.Accountdetail[0].Name} placeholder="Account Holder Name" disabled type="text" className="form-control" />

                                    </Col>
                                      </Row> */}
                     </Col>
                         </Row>
                         <div>
                          <Button
                          type="submit"
                          color="primary"
                        
                          className="mr-1 waves-effect waves-light"
                        >
                          View
                        </Button>
                        <Button
                          type="button"
                          color="primary"
                          style={{"marginLeft" : "10px"}}
                          className="mr-1 waves-effect waves-light"
                         onClick={this.reset}
                        >
                          Reset
                        </Button>
                        
                          </div>

                         
                         </AvForm>

                         </div> 
                         </div>
                       
                          </CardBody>
                         </Card>


                         
                         </Col>
                         </Row>
{
  this.state.show?    <div  style={{height:'700px', marginTop:'5px'}}>
  <BoldReportViewerComponent id="reportviewer-container"
reportServiceUrl = {'https://boldreports.shinewellinnovation.com/reporting/reportservice/api/Viewer'}
reportServerUrl= {'https://boldreports.shinewellinnovation.com/reporting/api/site/site1'}
serviceAuthorizationToken = {'bearer '+this.state.BoldToken[0].Token}
reportPath = {'/Ghana/MemberStatement'}
parameters ={parameters}
>
</BoldReportViewerComponent>

</div> : null
}


                         
                         </Container>
                         </div>
                         </React.Fragment>

                         


   
      </>
    );
  }
}
export default compose(container)(pageReport_MemberStatement);
