/* eslint-disable */


import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal
} from "reactstrap";

import classnames from "classnames";
import Select from "react-select";
import Dropzone from "react-dropzone";
import { useSelector } from "react-redux";
// Redux
import { withRouter, Link } from "react-router-dom";
// availity-reactstrap-validation
import {
  AvForm,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
import SweetAlert from "react-bootstrap-sweetalert"
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import { Fn_DisplayData, Fn_AddEditData, Fn_FillListData, Fn_GetReport, Fn_ChangeStateValue } from "../../store/functions";
import "./datatables.scss"
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

function getdate() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');

  return  `${year}-${month}-${day}`;

}




class pageAddEdit_CashLoan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      formData: {},
      activeTab: 1,
      passedSteps: [1],
      //dropdowns
      Accountdetail: [{
        Name : ""
      }],
      RecipentDetail: [
        {
          Name : ""
        }
      ],
      prov : false,
      maritalStatus: [],
      accounttypevalue: 0,
      bloodGroup: [],
      gender: [],
      education: [],
      profession: [],
      relationShip: [],
      nationality: [],
      typeofRegCert: [],
      accounttype : [],
      SAccountType :0,
     
            BoldToken : [{
              Token : ''
            }],
      Account  : [],
      success_msg : false,
      VoucherId : 0,
      PreLoanDetails: [{}],
      IsAPR : false,
      Interest : [{
        MainInterest : 0
      }],
      gridData: [],
      modal_fullscreen: false,
      dynamic_title: "",
      dynamic_description: "",
      page: 1,
      sizePerPage: 10,
      productData: []
    };

  
  
    this.toggleTab.bind(this);
    this.obj = this;
    this.formTitle = "CASH LOAN ";
    this.breadCrumbTitle = "CASH LOAN ";
    this.breadCrumbItem =  this.formTitle;
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/Membership";
    this.API_URL_SAVE = API_WEB_URLS.TransactionDeposit + "/0/token";
    this.pushFormName = "/dashboard";
  

    //Event Binding
    this.btnSave_onClick = this.btnSave_onClick.bind(this);
    this.btnCancel_onClick = this.btnCancel_onClick.bind(this);
    this.syno =  this.syno.bind(this);
    this.tog_fullscreen = this.tog_fullscreen.bind(this);
    this.prin  =  this.prin.bind(this);
    this.printtest  =  this.printtest.bind(this);
    this.btnReset_onClick  =  this.btnReset_onClick.bind(this);
    this.onchangeInt  =  this.onchangeInt.bind(this);
    this.ViewAmortization  =  this.ViewAmortization.bind(this);
    this.onloanamount  = this.onloanamount.bind(this);

  }




  componentDidMount() {
    //Filling DropDowns
    Fn_FillListData(this.obj, "BoldToken", API_WEB_URLS.MASTER + "/0/token/BoldToken/Id/0");
    Fn_FillListData(this.obj, "Interest", API_WEB_URLS.MASTER + "/0/token/SchemeInterest/Id/"+4);

     Fn_FillListData(this.obj, "AccountTypeScheme", API_WEB_URLS.MASTER + "/0/token/AccountTypeSchemesByAccountType/Id/14");
      
    
    const obj = JSON.parse(localStorage.getItem("authUser"));
    this.setState({ MembershipNo: obj.name, Name: obj.username, aid: obj.uid , role:obj.role });
    this.GetMemberName(obj.name);

    const { id } = this.props.match.params;
    if (id) {
      this.setState({ id: id });
      this.breadCrumbItem = "Edit " + this.formTitle;
      Fn_DisplayData(this.obj, id, this.API_URL + "/Id");
    } else {
      this.setState({ id: 0 });
    }
  }



  btnSave_onClick(event, formData) {



    

    var F_MemberMaster  =  this.state.PreLoanDetails[0].Id;
    var LoanAmount  =  formData.LoanAmountRequired;
    var MembershipNo  =  this.state.MembershipNo;
    var F_AccountTypeScheme  =  formData.LoanType;
    var LoanAmountLimitByShares  =  this.state.PreLoanDetails[0].LoanLimitByShare;
    var LoanAmountLimitByGoldStock  =  this.state.PreLoanDetails[0].CaveoLimit;
    var GrossLoanLimit  =  this.state.PreLoanDetails[0].GrossLoanLimit;
    var OutstandingLoan  =  this.state.PreLoanDetails[0].OutstandingLoan;
    var NetLoanLimit  =  this.state.PreLoanDetails[0].NetLoanLimit;
    var LoanAmountRequired  =  formData.LoanAmountRequired;
    var LoanDuration  =  formData.LoanDuration;
    var APR  =  this.state.Interest[0].MainInterest;
    var F_PaymentFrequency  =  formData.PaymentFrequency;
    var MonthlyRepayment  =  formData.MonthlyRepayment;
    var TotalAmountPayable  =  formData.TotalAmountPayable;
    var F_BranchMaster  =  1;
    var TotalInterestPayable  =  formData.TotalInterestPayable;
    var OpeningDate  =  formData.OpeningDate;
    var Remarks  =  'Loan';
    var AName  =  this.state.PreLoanDetails[0].Name;

    var UserId  =  this.state.aid;
    let numericValue = parseFloat(NetLoanLimit.replace(/,/g, ''));
   

    if (Number(LoanAmount) > numericValue){
      alert('Loan Amount can not be more than loan limit.')
    }

    else {


    let vformData = new FormData();
   
    vformData.append("F_MemberMaster", F_MemberMaster);
    vformData.append("UserId", UserId);
    vformData.append("LoanAmount", LoanAmount);
    vformData.append("MembershipNo", MembershipNo);
    vformData.append("F_AccountTypeScheme", F_AccountTypeScheme);
    vformData.append("MembershipNo", MembershipNo);
    vformData.append("LoanAmountLimitByShares", LoanAmountLimitByShares);
    vformData.append("LoanAmountLimitByGoldStock", LoanAmountLimitByGoldStock);
    vformData.append("GrossLoanLimit", GrossLoanLimit);
    vformData.append("OutstandingLoan", OutstandingLoan);
    vformData.append("NetLoanLimit", NetLoanLimit);
    vformData.append("LoanAmountRequired", LoanAmountRequired);
    vformData.append("LoanDuration", LoanDuration);
    vformData.append("APR", APR);
    vformData.append("F_PaymentFrequency", F_PaymentFrequency);
    vformData.append("MonthlyRepayment", MonthlyRepayment);
    vformData.append("TotalAmountPayable", TotalAmountPayable);
    vformData.append("F_BranchMaster", F_BranchMaster);
    vformData.append("TotalInterestPayable", TotalInterestPayable);
    vformData.append("OpeningDate", OpeningDate);
    vformData.append("Remarks", Remarks);
    vformData.append("AName", AName);
    Fn_AddEditData(this.obj, { arguList: { id: 0, formData: vformData } }, "AddCashLoan/0/token", "#", true , "LoanId" , this.printtest);

    }
  }



  btnCancel_onClick = event => {
    event.preventDefault();
    //this.props.history.goBack();
    this.props.history.push(this.pushFormName);
  };

  
  btnReset_onClick = event => {
    this.setState({
      Balance : [{
        Balance : 0
       }],
      MemberName : [
  {
    Name : ''
  }
      ],
      MembershipNo : ''
    });
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        var modifiedSteps = [...this.state.passedSteps, tab];
        this.setState({
          activeTab: tab,
          passedSteps: modifiedSteps,
        });
      }
    }
  }



  GetAccountDetail =(event)=> {
    var value  =  event.target.value;
    Fn_FillListData(this.obj, "Accountdetail", API_WEB_URLS.MASTER + "/0/token/ListOfAccountsByMember/Id/"+this.state.MemberName[0].Id);
  }



  syno () {

    this.setState({
      success_msg : false
    });

      };





  GetMemberName =(e)=> {
    if(e == 3 || e == 12){
      var value = event.target.value;
      value =  value + '-';
      
      this.setState({
        MembershipNo: value
      });
    }


    let vformData = new FormData();
    vformData.append("MembershipNo", e);
    Fn_GetReport(this.obj, {  arguList: {id: 0, formData: vformData } }, "PreLoanDetails/0/token", "PreLoanDetails", true );
    

  }


  printtest () {
    
    var mywindow = window.open('', 'PRINT', 'height=400,width=600');

    mywindow.document.write('<html><head><title>' + '</title>');
    mywindow.document.write('</head><body >');
    mywindow.document.write('<h1>' + 'Loan Amortization Schedule'  + '</h1>');
    mywindow.document.write(document.getElementById('amor').innerHTML);
    mywindow.document.write('</body></html>');

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    mywindow.print();
    mywindow.close();
   
  }

  prin() {

    this.timer = setTimeout(() => {
      var reportviewerObj = $("#reportviewer-container").data("boldReportViewer"); reportviewerObj.print();
    }, 1000);
  
  }

 


  tog_fullscreen() {
    this.setState(prevState => ({
      modal_fullscreen: !prevState.modal_fullscreen,
    }))
    this.removeBodyCss()
  }

  removeBodyCss() {
    document.body.classList.add("no_padding")
  }


  onchangeInt (event) {

    if (event.target.value ==  1){
      this.setState({IsAPR : true})
    }

    else {
      this.setState({IsAPR : false})
    }
    

  }
 

   calculateAmortizationSchedule = (interestRate, periodMonths, loanAmount) => {
    const monthlyInterestRate = interestRate / 12 / 100;
    const numPayments = periodMonths;

    const monthlyPayment =
      (loanAmount *
        (monthlyInterestRate * Math.pow(1 + monthlyInterestRate, numPayments))) /
      (Math.pow(1 + monthlyInterestRate, numPayments) - 1);

    const schedule = [];

    let remainingBalance = loanAmount;

    for (let month = 1; month <= numPayments; month++) {
      const interestPayment = remainingBalance * monthlyInterestRate;
      const principalPayment = monthlyPayment - interestPayment;
      remainingBalance -= principalPayment;

      schedule.push({
        Month: month,
        Principal: principalPayment.toFixed(2),
        Interest: interestPayment.toFixed(2),
        TotalPayment: monthlyPayment.toFixed(2),
        RemainingBalance: remainingBalance.toFixed(2),
      });
    }

    return schedule;
  };



  ViewAmortization () {
    var LoanAmount  =  this.state.LoanAmount;
    var InterestRate  =  this.state.Interest[0].MainInterest;
    var LoanDuration  = this.state.LoanDuration;
    var PaymentFrequency  =  this.state.PaymentFrequency;

    const paymentSchedule = this.calculateAmortizationSchedule(InterestRate,LoanDuration, LoanAmount);


    this.setState({gridData : paymentSchedule, modal_fullscreen : true});



  }

onloanamount (event){

  const interestRate  =  8;
  const monthlyInterestRate = interestRate / 12 / 100;
  const numPayments = this.state.LoanDuration;
const loanAmount  = event.target.value;
  const monthlyPayment =
    (loanAmount *
      (monthlyInterestRate * Math.pow(1 + monthlyInterestRate, numPayments))) /
    (Math.pow(1 + monthlyInterestRate, numPayments) - 1);



    const schedule = [];

    let remainingBalance = loanAmount;
    
    for (let month = 1; month <= numPayments; month++) {
      const interestPayment = remainingBalance * monthlyInterestRate;
      const principalPayment = monthlyPayment - interestPayment;
      remainingBalance -= principalPayment;
    
      schedule.push({
        Month: month,
        Principal: principalPayment.toFixed(2),
        Interest: interestPayment.toFixed(2),
        TotalPayment: monthlyPayment.toFixed(2),
        RemainingBalance: remainingBalance.toFixed(2),
      });
    }
    
    // Calculate total amount payable after the loan
    const totalAmountPayable = schedule.reduce(
      (total, entry) => total + parseFloat(entry.TotalPayment),
      0
    );
    
    // Calculate total interest payable
    const totalInterestPayable = schedule.reduce(
      (total, entry) => total + parseFloat(entry.Interest),
      0
    );







    this.setState({
      MonthlyRepayment : monthlyPayment.toFixed(2),
      TotalAmountPayable : totalAmountPayable.toFixed(2),
      TotalInterestPayable : totalInterestPayable.toFixed(2)
    })
}



  render() {

    const columns = [{
      dataField: 'Month',
      text: 'Month',
      sort: true
    }, {
      dataField: 'Interest',
      text: 'Interest',
      sort: true
    }, {
      dataField: 'Principal',
      text: 'Principal',
      sort: true
    }, {
      dataField: 'RemainingBalance',
      text: 'RemainingBalance',
      sort: true
    },
    {
      dataField: 'TotalPayment',
      text: 'TotalPayment',
      sort: true
    },];

    const defaultSorted = [{
      dataField: 'Month',
      order: 'asc'
    }];

    const pageOptions = {
      sizePerPage: 12,
      totalSize: this.state.gridData, // replace later with size(customers),
      custom: true,
    }
    const selectRow = {
      mode: 'checkbox'
    }
    const { SearchBar } = Search;



    var parameters = [{
      name: 'Id',
      values: [this.state.VoucherId]
      }
    ];


    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.breadCrumbTitle}
              breadcrumbItem={this.breadCrumbItem}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                   
                    <div className="wizard clearfix">
                      <div className="content clearfix">
                            
                            <AvForm className="needs-validation" onValidSubmit={this.btnSave_onClick}>
                         
                                <Row>
                                  <Col lg="12">
                                  <Card>
                                    <CardBody>
                                      <h4 className="card-title mb-4">CASH LOAN</h4>
                                      <Row>


                                      <Row>
                                        <Col sm="2">
                                          <label htmlFor="membershipType" className="col-form-label">Opening Date</label>
                                        </Col>
                                        <Col sm="6">
                                        <AvField name="OpeningDate"   value={this.state.formData.OpeningDate == undefined ? getdate() : this.state.formData.OpeningDate}   type="date" className="form-control"  disabled />
                                        </Col>
                                                                              </Row>

                                        <Col sm="2">
                                                    <label htmlFor="membershipType" className="col-form-label">Member GIN</label>
                                                  </Col>
                                          <Col lg="4">

                                          <AvField name="membershipno" onChange={this.GetMemberName}  value={this.state.MembershipNo} placeholder="Member GIN" errorMessage="Enter Member GIN" validate={{ required: { value: true } }} type="text" disabled className="form-control" />
                                          
                                          </Col>

                                          <Col sm="2">
                                          <label htmlFor="membershipType" className="col-form-label"> Member</label>
                                        </Col>
                                        <Col sm="4">
                                        <AvField name="Member"  value={this.state.PreLoanDetails[0].Name}  placeholder=" Member Name" errorMessage=" Sender Name"  type="text" disabled className="form-control" />
                                         
                                        </Col>
                                        </Row>




<h3>Worth and Security </h3>

                                     <Row>
                                        <Col sm="2">
                                          <label htmlFor="membershipType" className="col-form-label">Shares</label>
                                        </Col>
                                        <Col sm="4">
                                        <AvField name="Share"   value={this.state.PreLoanDetails[0].Share} placeholder="Shares"  type="text" className="form-control"  disabled/>
                                        </Col>
                                      
                                      
                                      
                                        <Col sm="2">
                                          <label htmlFor="membershipType" className="col-form-label">Gold Stock</label>
                                        </Col>
                                        <Col sm="4">
                                          <AvField  name="Caveo" label="" value={this.state.PreLoanDetails[0].Caveo} placeholder="GoldStock" errorMessage="GoldStock"  type="number" className="form-control"  disabled>
                                       
                                          </AvField>
                                        </Col>
                                        </Row>


<h3>Loan Eligibility </h3>
                                        <Row>

                                        <Col sm="2">
                                                <label htmlFor="membershipType" className="col-form-label">Loan limit by Shares</label>
                                              </Col>
                                      <Col lg="4">
                                      <AvField name="LoanLimitByShare"  value={this.state.PreLoanDetails[0].LoanLimitByShare} placeholder="LoanLimitByShare"    type="text" className="form-control" disabled />
                                        </Col>
                                       

                                        

                                        <Col sm="2">
                                          <label htmlFor="membershipType" className="col-form-label">Loan Limit By Gold Stock</label>
                                        </Col>
                                        <Col sm="4">
                                          <AvField  name="CaveoLimit" label="" value={this.state.PreLoanDetails[0].CaveoLimit} placeholder="LoanLimitByGoldStock" errorMessage="Loan Limit By Gold Stock"  type="text" className="form-control"  disabled>
                                           
                                          </AvField>
                                        </Col>
                                      </Row>


                                      <Row>
                                        <Col sm="2">
                                          <label htmlFor="membershipType" className="col-form-label">Gross Loan Limit</label>
                                        </Col>
                                        <Col sm="4">
                                          <AvField  name="GrossLoanLimit" label="" value={this.state.PreLoanDetails[0].GrossLoanLimit} placeholder="GrossLoanLimit"   type="text" className="form-control"  disabled>
                                           
                                          </AvField>
                                        </Col>


                                        <Col sm="2">
                                          <label htmlFor="membershipType" className="col-form-label">OutstandingLoan</label>
                                        </Col>
                                        <Col sm="4">
                                          <AvField  name="OutstandingLoan" label="" value={this.state.PreLoanDetails[0].OutstandingLoan} placeholder="OutstandingLoan"  type="text" className="form-control"  disabled>
                                           
                                          </AvField>
                                        </Col>

                                      </Row>


                                      

                                      <Row>
                                        <Col sm="2">
                                          <label htmlFor="membershipType" className="col-form-label">NetLoanLimit</label>
                                        </Col>
                                        <Col sm="4">
                                          <AvField  name="NetLoanLimit" label="" value={this.state.PreLoanDetails[0].NetLoanLimit} placeholder="OutstandingLoan"  type="text" className="form-control"  disabled>
                                           
                                          </AvField>
                                        </Col>
                                        </Row>



                                        <h3>Loan Request </h3>
                                        <Row>

                                        <Col sm="2">
                                          <label htmlFor="membershipType" className="col-form-label">Loan Amount Required</label>
                                        </Col>
                                        <Col sm="4">
                                          <AvField  name="LoanAmountRequired" label="" value={this.state.formData.LoanAmountRequired} onChange={(event) => {
  Fn_ChangeStateValue(this.obj, "LoanAmount", event.target.value);
  this.onloanamount(event);
}} placeholder="LoanAmountRequired"  type="number" className="form-control"  >
                                           
                                          </AvField>
                                        </Col>


                                      </Row>



                                      <Row>
                                        <Col sm="2">
                                          <label htmlFor="membershipType" className="col-form-label">Loan Duration</label>
                                        </Col>
                                        <Col sm="4">
                                          <AvField  name="LoanDuration" label="" value={this.state.formData.LoanDuration} onChange={(event)=> Fn_ChangeStateValue(this.obj, "LoanDuration", event.target.value)} placeholder="LoanDuration"  type="number" className="form-control"  >
                                           
                                          </AvField>
                                        </Col>

                                        <Col sm="2">
                                          <label htmlFor="membershipType" className="col-form-label">Loan Type</label>
                                        </Col>
                                        <Col sm="4">
                                          <AvField name="LoanType" label="" value={this.state.formData.LoanType}  errorMessage="Select Loan Type" validate={{ required: { value: true } }} type="select" className="form-select" >
                                          <option value={-1} defaultValue label={"Select"} />
                                            {this.state.AccountTypeScheme
                                              ? this.state.AccountTypeScheme.map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.Name} />
                                                  )
                                                )
                                              : null}
                                          </AvField>
                                        </Col>


                                      </Row>

                                      





                                      <Row>
                                        <Col sm="2">
                                          <label htmlFor="membershipType" className="col-form-label">APR (Interest Rate)</label>
                                        </Col>
                                        <Col sm="4">
                                          <AvField  name="InterestRate" label="" value={this.state.Interest[0].MainInterest+'%'} placeholder="InterestRate"  type="text" className="form-control"  disabled>
                                          </AvField>
                                        </Col>


                                        <Col sm="2">
                                          <label htmlFor="membershipType" className="col-form-label">Select Plan</label>
                                        </Col>
                                        <Col sm="4">
                                          <AvField name="PlanTypeId" label="" value={this.state.formData.PlanTypeId} onChange={this.onchangeInt}   type="select" className="form-select" disabled >
                                            {/* <option value={-1} defaultValue label={"Select Plan"} />
                                            <option value={1} defaultValue label={"APR"} /> */}
                                            <option value={2} defaultValue label={"EMI"} />
                                          </AvField>
                                        </Col>
                                      </Row>

                                     


                                      {/* {this.state.IsAPR == true ? 

                                      <Row>
                                      <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">Select APR Type</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="APRTypeId" label="" value={this.state.formData.APRTypeId}  errorMessage="Select APR Type" validate={{ required: { value: true } }} type="select" className="form-select" >
                                            <option value={-1} defaultValue label={"Select APR Type"} />
                                            <option value={1} defaultValue label={"Reducing Balance "} />
                                            <option value={2} defaultValue label={"Variable Payments"} />
                                          </AvField>
                                        </Col>
                                      </Row>

                                      : null }  */}

<Row>
                                      <Col sm="2">
                                          <label htmlFor="membershipType" className="col-form-label">Select Payment Frequency</label>
                                        </Col>
                                        <Col sm="4">
                                          <AvField name="PaymentFrequency" label="" value={this.state.formData.PaymentFrequency} onChange={(event)=> Fn_ChangeStateValue(this.obj, "PaymentFrequency", event.target.value)}  type="select" className="form-select" >
                                            {/* <option value={-1} defaultValue label={"Select PaymentFrequency"} />
                                            <option value={1} defaultValue label={"Daily (365/Yr)"} />
                                            <option value={2} defaultValue label={"Daily (360/Yr)"} />
                                            <option value={3} defaultValue label={"Weekly (52/Yr)"} />
                                            <option value={4} defaultValue label={"Biweekly (26/Yr)"} />
                                            <option value={5} defaultValue label={"Semi-Month (24/Yr)"} /> */}
                                            <option value={-1} defaultValue label={"Select PaymentFrequency"} />
                                            <option value={6}  label={"Monthly"} />
                                            {/* <option value={7} defaultValue label={"BiMonthly (6/Yr)"} />
                                            <option value={8} defaultValue label={"Quarterly (4/Yr)"} />
                                            <option value={9} defaultValue label={"Semi-Annual (2/Yr)"} />
                                            <option value={10} defaultValue label={"Annual (1/Yr)"} /> */}
                                          </AvField>
                                        </Col>


                                        <Col sm="2">
                                          <label htmlFor="membershipType" className="col-form-label">Monthly Repayment</label>
                                        </Col>
                                        <Col sm="4">
                                          <AvField  name="MonthlyRepayment" label="" value={this.state.MonthlyRepayment} placeholder="MonthlyRepayment"  type="number" className="form-control"  disabled>
                                           
                                          </AvField>
                                        </Col>
                                      </Row>



                                      <Row>
                                        <Col sm="2">
                                          <label htmlFor="membershipType" className="col-form-label">Total Amount Payable</label>
                                        </Col>
                                        <Col sm="4">
                                          <AvField  name="TotalAmountPayable" label="" value={this.state.TotalAmountPayable} placeholder="TotalAmountPayable"  type="number" className="form-control"  disabled>
                                           
                                          </AvField>
                                        </Col>


                                        <Col sm="2">
                                          <label htmlFor="membershipType" className="col-form-label">Total Interest Payable</label>
                                        </Col>
                                        <Col sm="4">
                                          <AvField  name="TotalInterestPayable" label="" value={this.state.TotalInterestPayable} placeholder="TotalAmountPayable"  type="number" className="form-control"  disabled>
                                           
                                          </AvField>
                                        </Col>
                                      </Row>

                                      
                                     


                                      
                <Card >
                  <CardBody>
                    <CardTitle className="h4">
                    
                    </CardTitle>
                 
                    <div>
                     
                      <Modal
                        size="xl"
                        isOpen={this.state.modal_fullscreen}
                        toggle={this.tog_fullscreen}
                        className="modal-fullscreen"
                      >
                        <div className="modal-header">
                          <h5
                            className="modal-title mt-0"
                            id="exampleModalFullscreenLabel"
                          >
                           Loan Amortization Schedule
                          </h5>
                          <button
                            onClick={() =>
                              this.setState({ modal_fullscreen: false })
                            }
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body" >
 
 

 
                        <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='Month'
                      columns={columns}
                      data={this.state.gridData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='Month'
                          columns={columns}
                          data={this.state.gridData}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>

                              <Row className="mb-2">
                                <Col md="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>

                              </Row>

                              <div id="amor">

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"Month"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      //selectRow={selectRow}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />

                                  </div>
                                </Col>
                              </Row>

                              </div>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>

 
 
                     </div>
                        <div className="modal-footer">
                        {/* <button
                            type="button"
                            onClick={this.printtest}
                            className="btn btn-secondary"
                            data-dismiss="modal"
                          >
                            Print
                          </button> */}
                          <button
                            type="button"
                            onClick={this.tog_fullscreen}
                            className="btn btn-secondary"
                            data-dismiss="modal"
                          >
                            Close
                          </button>
                      
                        </div>
                      </Modal>
                    </div>
                  </CardBody>
                </Card>


         

                          <div className="d-flex flex-wrap gap-2">
                            <Button type="submit" color="primary">
                              Save
                            </Button>{" "}

                            {this.state.success_msg ? (
                      <SweetAlert
                        title="Loan Created Successful!"
                        success
                        confirmBtnBsStyle="success"
                        onConfirm={this.syno}
                      >
                       
                      </SweetAlert>
                    ) : null}
                            {/* {" "}
                                <Button
                          type="button"
                          color="secondary"
                          className="waves-effect"
                          onClick={this.btnReset_onClick}
                        >
                              Reset
                            </Button> */}
                            {" "}
                                <Button 
                          type="button"
                          color="secondary"
                          className="waves-effect"
                          onClick={this.ViewAmortization}
                        >
                              View Amortization Schedule
                            </Button>
                            
                            {" "}
                                <Button
                          type="button"
                          color="secondary"
                          className="waves-effect"
                          onClick={this.printtest}
                        >
                              Print
                            </Button>
                            
                            {" "}
                                <Button
                          type="button"
                          color="secondary"
                          className="waves-effect"
                          onClick={this.btnCancel_onClick}
                        >
                              Exit
                            </Button>

                            
                          </div>

</CardBody>
                      </Card>
                    </Col>
                    
                      </Row>
                    
                      </AvForm>
                      </div>
                    </div>
                  </CardBody>
                </Card>

        
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default compose(container)(pageAddEdit_CashLoan);
