export const API_WEB_URLS = {
  
  BASE: "https://api.societiesbank.com/api/V1/",
 // BASE: "https://localhost:44362/api/V1/",
  LOGIN: "Authentication/Login",
  IMG_URL : "https://api.societiesbank.com/api/V1/MemberImages/PDF/",
  //
  MASTER: "Masters",
  MASTER_COUNTRY: "CountryMaster",

 SEARCHMEMBERSHIPNO : "SearchMembership",
 MemberMasterEntity : "MemberMasterEntity",
  //

  MEMBER:"MemberMaster",
  PersonToPersonRequest : "PersonToPersonRequest",
  AccountToAccountRequest : "AccountToAccountRequest",
  TransactionWithdraw : "TransactionWithdraw",
  TransactionDeposit : "TransactionDeposit",
  ChangePassword : "ChangePasswordMember",
  AdvisorMaster : "AgentMaster"

};
