import React, { Component } from "react";
import PropTypes from 'prop-types';
import ReactDrawer from 'react-drawer';
import 'react-drawer/lib/react-drawer.css';

import { connect } from "react-redux";

import { Link } from "react-router-dom";

// reactstrap
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import RightSidebar from '../CommonForBoth/RightSidebar';

import megamenuImg from "../../assets/images/megamenu-img.png";
import logo from "../../assets/images/logo.svg";
import logoLight from "../../assets/images/logo-light.png";
import logoLightSvg from "../../assets/images/logo-light.svg";
import logoDark from "../../assets/images/logo-dark.png";
import {Button} from "reactstrap";
// import images


// Redux Store
import { toggleRightSidebar } from "../../store/actions";

//i18n
import { withTranslation } from "react-i18next";
import { Fn_FillListData } from "store/functions";
import { API_WEB_URLS } from "constants/constAPI";
import { compose } from "recompose";
import { container } from "store/Containers/cntCommon";


const getUserName = () => {
  if (localStorage.getItem("authUser")) {
    const obj = JSON.parse(localStorage.getItem("authUser"))
    return obj.MembershipType;
  }
}

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isSearch: false,
      open: false,
      position: 'right',
      IsBalance: false,
      ViewBalance: [{}],
    }
    this.obj = this
    this.toggleMenu = this.toggleMenu.bind(this)
    this.toggleFullscreen = this.toggleFullscreen.bind(this)
    this.toggleSearch = this.toggleSearch.bind(this)
    this.toggleRightDrawer = this.toggleRightDrawer.bind(this);
    this.closeDrawer = this.closeDrawer.bind(this);
    this.onDrawerClose = this.onDrawerClose.bind(this);
    this.HandleBalance = this.HandleBalance.bind(this);
  }

  toggleSearch = () => {
    this.setState({ isSearch: !this.state.isSearch })
  }
  HandleBalance(){
    const obj = JSON.parse(localStorage.getItem("authUser"));
    Fn_FillListData(this.obj, "ViewBalance", API_WEB_URLS.MASTER + "/0/token/CheckBalance/Id/"+obj.uid);
    this.setState({
      IsBalance: !this.state.IsBalance
    })
    setTimeout(() => {
      this.setState({
        IsBalance: !this.state.IsBalance
      })
    }, 4000);
  }
  /**
   * Toggle sidebar
   */
  toggleMenu() {
    this.props.openLeftMenuCallBack()
  }

  /**
   * Toggles the sidebar
   */
  toggleRightDrawer() {
    this.setState({ position: 'right' });
    this.setState({ open: !this.state.open });
  }
  closeDrawer() {
    this.setState({ open: false });
  }
  onDrawerClose() {
    this.setState({ open: false });
  }

  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  render() {


    return (
      <React.Fragment>
        <header id="page-topbar" style={{backgroundColor:'orange'}}>
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box">
                <Link to="/" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={logoLightSvg} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    {/* <img src={logoLight} alt="" height="19" /> */}

                    <h5>
                      <b style={{ color: "white" }}>
                        Federated Co-Operative Multi-Purpose Society Ltd
                      </b>
                    </h5>
                  </span>
                </Link>

                <Link to="/" className="logo logo-light">
                  <span className="logo-sm">
                    <img src={logoLightSvg} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    {/* <img src={logoLight} alt="" height="19" /> */}

                    <h5>
                      <b style={{ color: "white" }}>
                        Federated Co-Operative Multi-Purpose Society Ltd
                      </b>
                    </h5>
                  </span>
                </Link>
              </div>

              <button
                type="button"
                className="btn btn-sm px-3 font-size-16 d-lg-none header-item"
                data-toggle="collapse"
                onClick={this.toggleMenu}
                data-target="#topnav-menu-content"
              >
                <i className="fa fa-fw fa-bars" />
              </button>
            </div>

            <div className="d-flex">
              <div className="dropdown d-inline-block d-lg-none ms-2">
                <button
                  type="button"
                  className="btn header-item noti-icon"
                  id="page-header-search-dropdown"
                  onClick={() => {
                    this.setState({ isSearch: !this.state.isSearch });
                  }}
                >
                  <i className="mdi mdi-magnify" />
                </button>
                <div
                  className={
                    this.state.isSearch
                      ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                      : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                  }
                  aria-labelledby="page-header-search-dropdown"
                >
                  <form className="p-3">
                    <div className="form-group m-0">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={("Search") + "..."}
                          aria-label="Recipient's username"
                        />
                        <div className="input-group-append">
                          <button className="btn btn-primary" type="submit">
                            <i className="mdi mdi-magnify" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <span style={{color:'white',cursor:'pointer',marginTop:'27px',marginRight: "13px"}} onClick={this.HandleBalance}>{this.state.IsBalance ? this.state.ViewBalance[0].Balance : 'View Balance'}</span>
              <Link to="/deposit">
                <Button
                  color={"danger"}
                  className="waves-effect"
                  style={{ width: "100px", height: "40px", marginTop: "17px" }}
                >
                  Deposit
                </Button>
              </Link>

              {/* <LanguageDropdown /> */}

              <div className="dropdown d-none d-lg-inline-block ms-1">
                <button
                  type="button"
                  className="btn header-item noti-icon"
                  onClick={this.toggleFullscreen}
                  data-toggle="fullscreen"
                >
                  <i className="bx bx-fullscreen" />
                </button>
              </div>

             

              <NotificationDropdown />

              <div color="white" className="dropdown d-none d-lg-inline-block ms-1">
                <div style={{paddingTop:"24px"}} className="btn header-item noti-icon">
                  {getUserName()}
                  </div>

              </div>

              <ProfileMenu />
              
              {/* <div className="dropdown d-inline-block">
                <button
                  onClick={this.toggleRightDrawer} disabled={this.state.open}
                  type="button"
                  className="btn header-item noti-icon right-bar-toggle"
                >
                  <i className="bx bx-cog bx-spin" />
                </button>
              </div> */}
            </div>
          </div>
        </header>
        {/* <ReactDrawer
          open={this.state.open}
          position={this.state.position}
          onClose={this.onDrawerClose}
        >
          <RightSidebar onClose={this.onDrawerClose} />
        </ReactDrawer> */}
      </React.Fragment>
    );
  }
}

Header.propTypes = {
  openLeftMenuCallBack: PropTypes.func,
  t: PropTypes.any,
  toggleRightSidebar: PropTypes.func
}

const mapStatetoProps = state => {
  const { layoutType } = state.Layout
  return { layoutType }
}

export default compose(container)(Header)

