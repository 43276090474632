import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert"
import classnames from "classnames";
import Select from "react-select";
import Dropzone from "react-dropzone";
import { useSelector } from "react-redux";
// Redux
import { withRouter, Link } from "react-router-dom";
// availity-reactstrap-validation
import {
  AvForm,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";
// import AvDate from "@availity/reactstrap-validation-date";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import {
  Fn_DisplayData,
  Fn_AddEditData,
  Fn_FillListData,
  
  Fn_ChangeStateValue,
} from "../../store/functions";



export const DateString = (dd) =>{
  let d1 =  dd.replace('-', '');
  let d2 = d1.replace('-', '');
  return d2;
  }

class pageAddEdit_EntityMembership extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      formData: {},
      activeTab: 1,
      passedSteps: [1],
      passedStepsVertical: [1],
      //dropdowns
      membershipType: [],
      success_msg : false,
      countryRegistration: [],
      entityType: [],
      typeofRegCert:[],
      OwnerMemberName : [{
        Name : '',
        Id : 0
      }],
      OtherMemberName : [{
        Name : '',
        Id : 0
      }]
    };
    this.toggleTab.bind(this);
    this.obj = this;
    this.formTitle = "Entity Membership";
    this.breadCrumbTitle = "Entity Memberships";
    this.breadCrumbItem = "Add " + this.formTitle;
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/EntityEdit";
    this.API_URL_SAVE = API_WEB_URLS.MemberMasterEntity + "/0/token";
    this.pushFormName = "/memberships";

    //Event Binding
    this.btnSave_onClick = this.btnSave_onClick.bind(this);
    this.btnCancel_onClick = this.btnCancel_onClick.bind(this);
    this.syno  =  this.syno.bind(this);
  }
  componentDidMount() {
    localStorage.removeItem("PageName")
    localStorage.setItem("PageName", this.breadCrumbItem)

    const obj = JSON.parse(localStorage.getItem("authUser"));
    this.setState({ name: obj.username, email: obj.email, aid: obj.uid , role:obj.role });


    //Filling DropDowns
    Fn_FillListData(this.obj, "membershipType", API_WEB_URLS.MASTER + "/0/token/MembershipType/Id/0");
    Fn_FillListData(this.obj, "countryRegistration", API_WEB_URLS.MASTER + "/0/token/Country/Id/0");
    Fn_FillListData(this.obj, "entityType", API_WEB_URLS.MASTER + "/0/token/EntityType/Id/0");
    Fn_FillListData(this.obj, "typeofRegCert", API_WEB_URLS.MASTER + "/0/token/RegistrationDocumentType/Id/0");
    Fn_FillListData(this.obj, "designation", API_WEB_URLS.MASTER + "/0/token/GetDesignation/Id/1");

    const { id } = this.props.match.params;
    if (id) {
      this.setState({ id: id });
      this.breadCrumbItem = "Edit " + this.formTitle;
      Fn_DisplayData(this.obj, id, this.API_URL + "/Id");
    } else {
      this.setState({ id: 0 });
    }
  }
  btnSave_onClick(event, formData) {
    const obj = JSON.parse(localStorage.getItem("authUser"));
   
 

    var Established  =  new Date(formData.DateOfEstablished);
    Established = Established.getFullYear();
 
     var tod =  new Date();
 
     tod =  tod.getFullYear();
 
     var age = tod - Established;

      
       // var Mobile  =  Mobile.substring(Mobile.indexOf("-") + 1);


    let vformData = new FormData();
    //Information
    vformData.append("F_MembershipTypeMaster", 5);
    vformData.append("DateOfJoining",DateString(formData.DateOfJoining));
    vformData.append("BusinessName", formData.BusinessName);
    vformData.append("ShortName", formData.ShortName);
    vformData.append("BrandName", formData.BrandName);
    vformData.append("F_CountryMaster_Registration", formData.F_CountryMaster_Registration);
    vformData.append("DateOfEstablished", DateString(formData.DateOfEstablished));
    vformData.append("Age_Business", age);
    vformData.append("F_EntityTypeMaster", formData.F_EntityTypeMaster);
    vformData.append("Email", formData.Email);
    vformData.append("MobileNo_SMS", formData.MobileNo_SMS);
    vformData.append("NoOfBranches", formData.NoOfBranches);
    vformData.append("NoOfEmployees", formData.NoOfEmployees);
    vformData.append("Address", formData.Address);
    vformData.append("Pincode", formData.Pincode);
    vformData.append("MobileNo1", formData.MobileNo1);
    vformData.append("MobileNo2", formData.MobileNo2);
    vformData.append("PhoneNo1", formData.PhoneNo1);
    vformData.append("PhoneNo2", formData.PhoneNo2);


    //Address
    vformData.append("Website", formData.Website);
    vformData.append("F_RegistrationDocumentTypeMaster_1", formData.F_RegistrationDocumentTypeMaster_1);
    vformData.append("RegistrationNo_1", formData.RegistrationNo_1);
    vformData.append("F_RegistrationDocumentTypeMaster_2", formData.F_RegistrationDocumentTypeMaster_2);
    vformData.append("RegistrationNo_Business_2", formData.RegistrationNo_Business_2);
    vformData.append("F_MemberMaster_1", this.state.OwnerMemberName[0].Id);
    vformData.append("F_DesignationMaster_1", formData.F_DesignationMaster_1);
    vformData.append("F_MemberMaster_2", this.state.OtherMemberName[0].Id);
    vformData.append("F_DesignationMaster_2", formData.F_DesignationMaster_2);
    vformData.append("F_MemberMaster_Agent", obj.uid);
    vformData.append("Remarks", formData.Remarks);
    vformData.append("EntityLogo.ImageFileName", "dd");
    vformData.append("EntityLogo.ImageFile", this.state.EntityLogo);
    

    if (!this.state.id)    {
      Fn_AddEditData(this.obj, { arguList: { id: 0, formData: vformData } }, this.API_URL_SAVE, "#", true , "memberid");
    }
    else {
      Fn_AddEditData(this.obj, { arguList: { id: this.state.id, formData: vformData } }, this.API_URL_SAVE, this.pushFormName, true , "kuku");
    }
  }

  btnCancel_onClick = event => {
    event.preventDefault();
    //this.props.history.goBack();
    this.props.history.push(this.pushFormName);
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        var modifiedSteps = [...this.state.passedSteps, tab];
        this.setState({
          activeTab: tab,
          passedSteps: modifiedSteps,
        });
      }
    }
  }


  GetOwnerMemberName =(event)=> {

    var value  =  event.target.value;
    
    Fn_FillListData(this.obj, "OwnerMemberName", API_WEB_URLS.MASTER + "/0/token/SearchMem/1/Mem?ListFor="+value);
  

  }



  GetOtherMemberName =(event)=> {

    var value  =  event.target.value;
    
    Fn_FillListData(this.obj, "OtherMemberName", API_WEB_URLS.MASTER + "/0/token/SearchMem/1/Mem?ListFor="+value);
  
  }










  syno () {

    this.setState({
      success_msg : false
    });
        event.preventDefault();
       this.props.history.push(`${this.pushFormName}`, {});

      };

  DateChange = (event)=> {

    var birth  =  new Date(event.target.value);
     birth = birth.getFullYear();
      var tod =  new Date();
      tod =  tod.getFullYear();
      var age = tod - birth;
      this.setState({ fo: age });
  }

  render() {
    const options = [
      { value: "AK", label: "Alaska" },
      { value: "HI", label: "Hawaii" },
      { value: "CA", label: "California" },
      { value: "NV", label: "Nevada" },
      { value: "OR", label: "Oregon" },
      { value: "WA", label: "Washington" },
    ];
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.breadCrumbTitle}
              breadcrumbItem={this.breadCrumbItem}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">Entity Membership</h4>
                    <div className="wizard clearfix">
                      <div className="steps clearfix">
                        <ul>
                          <NavItem
                            className={classnames({
                              current: this.state.activeTab === 1,
                            })}
                          >
                            <NavLink
                              className={classnames({
                                active: this.state.activeTab === 1,
                              })}
                              onClick={() => {
                                this.toggleTab(1);
                              }}
                            >
                              <span className="number">1.</span> Entity
                              Information
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({
                              current: this.state.activeTab === 2,
                            })}
                          >
                            <NavLink
                              disabled={
                                !(this.state.passedSteps || []).includes(2)
                              }
                              className={classnames({
                                active: this.state.activeTab === 2,
                              })}
                              onClick={() => {
                                this.toggleTab(2);
                              }}
                            >
                              <span className="number">2.</span>{" "}
                              <span>Legalisation Confirmation</span>
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({
                              current: this.state.activeTab === 3,
                            })}
                          >
                            <NavLink
                              disabled={
                                !(this.state.passedSteps || []).includes(3)
                              }
                              className={
                                (classnames({
                                  active: this.state.activeTab === 3,
                                }),
                                "done")
                              }
                              onClick={() => {
                                this.toggleTab(3);
                              }}
                            >
                              <span className="number">3.</span> Confirm Detail
                            </NavLink>
                          </NavItem>
                          {/* <NavItem
                            className={classnames({
                              current: this.state.activeTab === 4,
                            })}
                          >
                            <NavLink
                              disabled={
                                !(this.state.passedSteps || []).includes(4)
                              }
                              className={
                                (classnames({
                                  active: this.state.activeTab === 4,
                                }),
                                "done")
                              }
                              onClick={() => {
                                this.toggleTab(4);
                              }}
                            >
                              <span className="number">4.</span> Confirm Detail
                            </NavLink>
                          </NavItem> */}
                        </ul>
                      </div>
                      <div className="content clearfix">
                       
                            
                      <AvForm className="needs-validation" onValidSubmit={this.btnSave_onClick}>
                          <TabContent activeTab={this.state.activeTab} className="body">
                            <TabPane tabId={1}>
                                <Row>
                                  <Col lg="6">
                                  <Card>
                                    <CardBody>
                                      <h4 className="card-title mb-4">Information</h4>
                                      <Row>
                                        <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">Membership Type</label>
                                        </Col>
                                <Col lg="6">
                                  <AvField name="F_MembershipTypeMaster"  value={this.state.formData.F_MembershipTypeMaster}  type="select" className="form-select"  disabled>
                                    <option value={5} defaultValue label={"Entity Member"} />
                                    {/* {this.state.membershipType
                                      ? this.state.membershipType.map(
                                          (option, key) => (
                                            <option key={option.Id} value={option.Id} label={option.Name} />
                                          )
                                        )
                                      : null} */}
                                  </AvField>
                                </Col>
                                </Row>
                                <Row>
                                <Col sm="4" className="mb-3">
                                          <label htmlFor="membershipType" className="col-form-label"> Date of Joining</label>
                                        </Col>
                                <Col lg="4">
                                     <AvField name="DateOfJoining" label="" value={this.state.formData.DateOfJoining === null ? ''   : this.state.formData.DateOfJoining} placeholder="Enter Date of Joining" errorMessage="Enter Date of Joining" validate={{ required: { value: true } }} type="date"  className="form-control" />
                                </Col>
                                </Row>
                                <Row>
                                <Col sm="4" className="mb-3">
                                          <label htmlFor="membershipType" className="col-form-label"> Business Name</label>
                                        </Col>
                                <Col lg="6">
                                
                                  <AvField name="BusinessName"  value={this.state.formData.BusinessName} placeholder="Enter Business Name" errorMessage="Enter Business Name" validate={{ required: { value: true } }} type="text" className="form-control" />
                                </Col>
                                </Row>

                                <Row>
                                <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label"> Short Name</label>
                                        </Col>
                                <Col lg="6">

                               
                                  <AvField name="ShortName"  value={this.state.formData.ShortName} placeholder="Enter Short Name" errorMessage="Enter Short Name" validate={{ required: { value: true } }} type="text" className="form-control" />
                                </Col>

                                </Row>

                                <Row>
                                <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label"> Brand Name</label>
                                        </Col>
                                <Col lg="6">

                               
                                  <AvField name="BrandName"  value={this.state.formData.BrandName} placeholder="Enter Brand Name"  type="text" className="form-control" />
                                </Col>

                                </Row>

                                <Row>
                                <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label"> Country Of Registration</label>
                                        </Col>
                                <Col lg="6">

                              
                                  <AvField name="F_CountryMaster_Registration"  value={this.state.formData.F_CountryMaster_Registration} errorMessage="Select Country Type" validate={{ required: { value: true } }} type="select" className="form-select" >
                                    <option value={-1} defaultValue label={"Select Country Type"} />
                                    {this.state.countryRegistration
                                      ? this.state.countryRegistration.map(
                                          (option, key) => (
                                            <option key={option.Id} value={option.Id} label={option.Name} />
                                          )
                                        )
                                      : null}
                                  </AvField>
                                </Col>

                                </Row>
                             

                              <Row>
                              <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label"> Date Established</label>
                                        </Col>
                                <Col lg="6">

                               
                                  <AvField name="DateOfEstablished" onChange={this.DateChange} value={this.state.formData.DateOfEstablished} placeholder="Enter Date Established" errorMessage="Enter Date Established" validate={{ required: { value: true } }} type="date"  className="form-control" />
                                </Col>
                                </Row>
                                <Row>

                                <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label"> Age</label>
                                        </Col>
                                <Col lg="6">

                               
                                  <AvField name="Age_Business"  value={this.state.fo === undefined ? this.state.formData.Age_Business   : this.state.fo} placeholder="Enter Age" errorMessage="Enter Age" disabled validate={{ required: { value: true } }} type="text" className="form-control" />
                                </Col>
                                </Row>
                                <Row>
                                <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label"> Entity Type</label>
                                        </Col>
                                <Col lg="6">
                                
                                  <AvField name="F_EntityTypeMaster"  value={this.state.formData.F_EntityTypeMaster} errorMessage="Select Entity Type" validate={{ required: { value: true } }} type="select" className="form-select" >
                                    <option value={-1} defaultValue label={"Select Entity Type"} />
                                    {this.state.entityType
                                      ? this.state.entityType.map(
                                          (option, key) => (
                                            <option key={option.Id} value={option.Id} label={option.Name} />
                                          )
                                        )
                                      : null}
                                  </AvField>
                                </Col>

                                </Row>
                                <Row>
                                <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">Email Address</label>
                                        </Col>
                                <Col lg="6">
                               
                                  <AvField name="Email"  value={this.state.formData.Email} placeholder="Enter Email Address" errorMessage="Enter Email Address" validate={{ required: { value: true } }} type="text" className="form-control" />
                                </Col>

                                </Row>

                                <Row>
                                <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label"> No. for SMS</label>
                                        </Col>
                                <Col lg="6">

                              
                                  <AvField name="MobileNo_SMS"  value={this.state.formData.MobileNo_SMS} placeholder="Enter No. for SMS" errorMessage="Enter No. for SMS" validate={{ required: { value: true } }} type="text" className="form-control" />
                                </Col>

                                </Row>
                                <Row>

                                <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label"> Number Of Branches</label>
                                        </Col>
                                <Col lg="6">

                               
                                  <AvField name="NoOfBranches"  value={this.state.formData.NoOfBranches} placeholder="Enter Number of Branches" errorMessage="Enter Number of Branches" validate={{ required: { value: true } }} type="text" className="form-control" />
                                </Col>

                                </Row>

                                <Row>

                                <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label"> Number Of Employees</label>
                                        </Col>
                                <Col lg="6">

                             
                                  <AvField name="NoOfEmployees"  value={this.state.formData.NoOfEmployees} placeholder="Enter Number of Employees" errorMessage="Enter Number of Employees" validate={{ required: { value: true } }} type="text" className="form-control" />
                                </Col>
                              </Row>
                              </CardBody>
                              </Card>
                            </Col>
                                <Col lg="6">
                                <Card>
                                      <CardBody>
                                  <div className="mb-3">
                                  <h4 className="card-title mb-4">Address</h4>
                                     
                                  </div>
                              <Row>
                              <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label"> Busniness Address</label>
                                        </Col>
                                <Col lg="6">
                                <AvField name="Address"  value={this.state.formData.Address} placeholder="Enter Your Business Address" errorMessage="Enter Your Business Address" validate={{ required: { value: true } }} type="text" className="form-control" />
                                   
                                </Col>
                                </Row>
                                {/* <Row>
                                  
                                <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">Full Address</label>
                                        </Col>
                                <Col lg="6">
                               
                                <AvField name="Address"  value={this.state.formData.Address} placeholder="Enter Your Full Address" errorMessage="Enter Your Full Address" validate={{ required: { value: true } }} type="text" className="form-control" />
                                </Col>

                                </Row> */}
                                <Row>
                                <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label"> Post Code</label>
                                        </Col>
                                <Col lg="6">
                              
                                  <AvField name="Pincode"  value={this.state.formData.Pincode} placeholder="Enter Post Code" errorMessage="Enter Post Code" validate={{ required: { value: true } }} type="text" className="form-control" />
                                </Col>
                                </Row>
                                <Row>
                                <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label"> Mobile Phone 1</label>
                                        </Col>
                                <Col lg="6">
                              
                                  <AvField name="MobileNo1"  value={this.state.formData.MobileNo1} placeholder="Enter Mobile Phone 1"  type="text" className="form-control" />
                                </Col>
                                </Row>
                                <Row>
                                <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label"> Mobile Phone 2</label>
                                        </Col>
                                <Col lg="6">
                               
                                  <AvField name="MobileNo2"  value={this.state.formData.MobileNo2} placeholder="Enter Mobile Phone 2"  type="text" className="form-control" />
                                </Col>
                                </Row>
                                <Row>
                                <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label"> Telephone 1</label>
                                        </Col>
                                <Col lg="6">
                               
                                  <AvField name="PhoneNo1"  value={this.state.formData.PhoneNo1} placeholder="Enter Telephone 1"  type="text" className="form-control" />
                                </Col>
                                </Row>
                                <Row>
                                <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label"> Telephone 2</label>
                                        </Col>
                                <Col lg="6">
                               
                                  <AvField name="PhoneNo2"  value={this.state.formData.PhoneNo2} placeholder="Enter Telephone 2"  type="text" className="form-control" />
                                </Col>
                                </Row>
                                <Row>
                                <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label"> Website </label>
                                        </Col>
                                <Col lg="6">
                               
                                  <AvField name="Website"  value={this.state.formData.Website} placeholder="Enter Website" errorMessage="Enter Website" validate={{ required: { value: true } }} type="text" className="form-control" />
                              
                              </Col>
                              </Row>


                              <Row>
                                  <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label"> Entity Logo </label>
                                        </Col>
                                  <Col lg="6">
                                  <AvField name="EntityLogo" onChange={(e) => Fn_ChangeStateValue(this.obj, 'EntityLogo', e.target.files[0])} value={this.state.formData.EntityLogo} placeholder="EntityLogo" errorMessage="EntityLogo" validate={{ required: { value: true } }} type="file" className="form-control" />
                                  </Col>
                                  </Row>
                                 
                              </CardBody>
                              </Card>
                                </Col>
                              </Row>
                           
                          </TabPane>
                          <TabPane tabId={2}>

                          
                             
                          <Row>
                                    <Col lg="6">
                                      <Card>
                                        <CardBody>
                                          <h4 className="card-title mb-4">Legalisation Confirmation</h4>
                                <Row>
                                <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label"> Type of Reg. Cert. </label>
                                        </Col>

                                  <Col lg="6">
                                  <AvField name="F_RegistrationDocumentTypeMaster_1"  value={this.state.formData.F_RegistrationDocumentTypeMaster_1} errorMessage="Select Type of Reg. Cert." validate={{ required: { value: true } }} type="select" className="form-select" >
                                    <option value={-1} defaultValue label={"Select Type of Reg. Cert."} />
                                    {this.state.typeofRegCert
                                      ? this.state.typeofRegCert.map(
                                          (option, key) => (
                                            <option key={option.Id} value={option.Id} label={option.Name} />
                                          )
                                        )
                                      : null}
                                  </AvField>
                                  </Col>
                                  </Row>
                                  <Row>
                                  <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label"> Registration No. </label>
                                        </Col>
                                  <Col lg="6">
                                  <AvField name="RegistrationNo_1" value={this.state.formData.RegistrationNo_1} placeholder="Enter Registration No." errorMessage="Enter Registration No." validate={{ required: { value: true } }} type="text" className="form-control" />
                                  </Col>
                                  </Row>
                                  <Row>
                                  <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label"> Browse Image </label>
                                        </Col>
                                  <Col lg="6">
                                  <AvField name="browseImage1"  value={this.state.formData.BrowseImage1} placeholder="Enter Browse Image" errorMessage="Enter Browse Image" validate={{ required: { value: true } }} type="file" className="form-control" />
                                  </Col>
                                </Row>

                                <Row>
                                

                                </Row>
                                </CardBody>
                                </Card>
                                </Col>
                                <Col>
                                <Card>

                                <CardBody>
  
                                <h4 className="card-title mb-4">.</h4>
                              



                                <Row>
                                <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label"> Type of Reg. Cert. </label>
                                        </Col>
                                  <Col lg="6">
                                  <AvField name="F_RegistrationDocumentTypeMaster_2"  value={this.state.formData.F_RegistrationDocumentTypeMaster_2} errorMessage="Select Type of Reg. Cert." validate={{ required: { value: true } }} type="select" className="form-select" >
                                    <option value={-1} defaultValue label={"Select Type of Reg. Cert."} />
                                    {this.state.typeofRegCert
                                      ? this.state.typeofRegCert.map(
                                          (option, key) => (
                                            <option key={option.Id} value={option.Id} label={option.Name} />
                                          )
                                        )
                                      : null}
                                  </AvField>
                                  </Col>
                                  </Row>
                                  <Row>
                                  <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label"> Registration No. </label>
                                        </Col>
                                  <Col lg="6">
                                  <AvField name="RegistrationNo_Business_2"  value={this.state.formData.RegistrationNo_Business_2} placeholder="Enter Registration No." errorMessage="Enter Registration No." validate={{ required: { value: true } }} type="text" className="form-control" />
                                  </Col>

                                  </Row>
                                  <Row>
                                  <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label"> Browse Image </label>
                                        </Col>
                                  <Col lg="6">
                                  <AvField name="browseImage2"  value={this.state.formData.BrowseImage2} placeholder="Enter Browse Image" errorMessage="Enter Browse Image" validate={{ required: { value: true } }} type="file" className="form-control" />
                                  </Col>
                                  </Row>
                                  <Row>
                                        </Row>
                                        
                                  </CardBody>
                                  </Card>
                                  </Col>
                                  </Row>
                                  <Row>
                                 
                                  <Col lg="6">
                                  <Card>
                                      <CardBody>
                                        <h4 className="card-title mb-4">Owner/CEO/MD</h4>
                                <Row>
                                <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label"> Membership No. </label>
                                        </Col>
                                  <Col lg="6">
                                    
                                  <AvField name="membershipNoCeo" onChange={this.GetOwnerMemberName}  value={this.state.formData.membershipNoCeo} placeholder="Enter Membership No." errorMessage="Enter Membership No." validate={{ required: { value: true } }} type="text" className="form-control" />
                                  </Col>
                                  </Row>
                                  <Row>
                                  <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">Member&apos;s Name </label>
                                        </Col>
                                  <Col lg="6">
                                  <AvField name="OwnerMemberName"  value={this.state.OwnerMemberName[0].Name  ==  '' && this.state.id > 0  ? this.state.formData.OwnerMemberName : this.state.OwnerMemberName[0].Name} placeholder="Enter Membership Name"  type="text" className="form-control" disabled/>
                                  </Col>
                               </Row>
                               <Row>
                               <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">Designation </label>
                                        </Col>
                                  <Col lg="6">

                                  <AvField name="F_DesignationMaster_1"  value={this.state.formData.F_DesignationMaster_1}  type="select" className="form-select"  >
                                    <option value={-1} defaultValue label={"Select Designation"} />
                                    {this.state.designation
                                      ? this.state.designation.map(
                                          (option, key) => (
                                            <option key={option.Id} value={option.Id} label={option.Name} />
                                          )
                                        )
                                      : null}
                                  </AvField>
                                  
                                  </Col>
                                </Row>
                                <Row>
                                

                                        </Row>
                                        </CardBody>
                                        </Card>
                                        </Col>
                                        <Col>
                                        <Card>

                                        <CardBody>
                                         
                                  <h4 className="card-title mb-4">Other</h4>
                                
                                  <Row>
                                <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label"> Membership No. </label>
                                        </Col>
                                  <Col lg="6">
                                    
                                  <AvField name="OwnermembershipNo" onChange={this.GetOtherMemberName} value={this.state.formData.OwnermembershipNo} placeholder="Enter Membership No." errorMessage="Enter Membership No." validate={{ required: { value: true } }} type="text" className="form-control" />
                                  </Col>
                                  </Row>
                                  <Row>
                                  <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">Member&apos;s Name </label>
                                        </Col>
                                  <Col lg="6">
                                  <AvField name="OtherMemberName"  value={this.state.OtherMemberName[0].Name == ''  && this.state.id > 0 ? this.state.formData.OtherMemberName : this.state.OtherMemberName[0].Name} placeholder="Enter Member Name"  type="text" className="form-control" disabled/>
                                  </Col>
                               </Row>
                               <Row>
                               <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">Designation </label>
                                        </Col>
                                  <Col lg="6">
                                  <AvField name="F_DesignationMaster_2"  value={this.state.formData.F_DesignationMaster_2}  type="select" className="form-select"  >
                                    <option value={-1} defaultValue label={"Select Designation"} />
                                    {this.state.designation
                                      ? this.state.designation.map(
                                          (option, key) => (
                                            <option key={option.Id} value={option.Id} label={option.Name} />
                                          )
                                        )
                                      : null}
                                  </AvField>
                                  </Col>
                                </Row>

                                </CardBody>
                                </Card>
                                </Col>
                                </Row>
                                <Row>
                                  

                                <Col lg="6">
                                  <Card>
                                      <CardBody>
                                        <h4 className="card-title mb-4">Payment</h4>
                              
                                <Row>
                                <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">Membership Fee </label>
                                        </Col>


                                  <Col lg="6">
                                  <AvField name="MembershipFee" value={this.state.formData.MembershipFee} placeholder="Enter Membership fee" errorMessage="Enter Membership Fee" validate={{ required: { value: true } }} type="text" className="form-control" />
                                  </Col>
                                  </Row>


                                  <Row>
                                  <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">Share Value </label>
                                        </Col>

                                  <Col lg="2">
                                  <AvField name="Share"  value={this.state.formData.Share} placeholder="S. Value" errorMessage="Enter Share Value" validate={{ required: { value: true } }} type="text" className="form-control" />
                                  </Col>
                                

                                  <Col sm="2">
                                          <label htmlFor="membershipType" className="col-form-label">No. of Shares </label>
                                        </Col>
                                  <Col lg="2">
                                  <AvField name="Shares"  value={this.state.formData.Shares} placeholder="Share No." errorMessage="Enter No. of Shares" validate={{ required: { value: true } }} type="text" className="form-control" />
                                  </Col>
                                </Row>
                                <Row>
                                <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">Remark </label>
                                        </Col>


                                  <Col lg="6">
                                  <AvField name="Remarks" value={this.state.formData.Remarks} placeholder="Enter Remark"  type="text" className="form-control" />
                                  </Col>
                                  
                                  </Row>
                                <Row>
                                

                                        </Row>
                                        </CardBody>
                                        </Card>
                                        </Col>
                                        <Col>
                                        <Card>
                                      <CardBody>
                                        <h4 className="card-title mb-4">Agent/Staff Capturing Data</h4>
                                        <Row>
                                          <Col sm="4" className="mb-3">
                                            <label htmlFor="agentCode" className="col-form-label">Agent/Staff Code</label>
                                          </Col>
                                          <Col sm="6">
                                            <input name="MemberId_Agent" label="" value={this.state.email} placeholder="Enter Agent/Staff Code" errorMessage="Enter Agent/Staff Code"  disabled type="text" className="form-control" />
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col sm="4" className="mb-3">
                                            <label htmlFor="agentName" className="col-form-label">Agent/Staff Name</label>
                                          </Col>
                                          <Col sm="6">
                                            <input name="AgentName" label="" value={this.state.name} placeholder="Enter Agent/Staff Name" errorMessage="Enter Agent/Staff Name"  type="text" disabled className="form-control" />
                                          </Col>
                                        </Row>
                                      </CardBody>
                            </Card>
                            </Col>
                            </Row>
                          </TabPane>
                          <TabPane tabId={3}>
                            <div className="row justify-content-center">
                              <Col lg="6">
                                <div className="text-center">
                                  <div className="mb-4">
                                    <i className="mdi mdi-check-circle-outline text-success display-4" />
                                  </div>
                                  <div>
                                    <h5>Confirm Detail</h5>
                                    <p className="text-muted">
                                      If several languages coalesce, the grammar
                                      of the resulting
                                    </p>
                                  </div>
                                </div>
                              </Col>
                            </div>
                          </TabPane>
                        </TabContent>
                        <div>
                        
<Button
                          type="submit"
                          color="primary"
                          className="mr-1 waves-effect waves-light"
                        >
                          Save
                        </Button>


                       
                       
                    

                        {this.state.success_msg ? (
                      <SweetAlert
                        title="Entity Saved Successfully!"
                        success
                       
                        confirmBtnBsStyle="success"
                      
                        onConfirm={this.syno}
                        
                      >
                        You clicked the button!
                      </SweetAlert>
                    ) : null}
                          </div>
                        </AvForm>
                      </div>
                      <div className="actions clearfix">
                        <ul>
                          <li
                            className={
                              this.state.activeTab === 1
                                ? "previous disabled"
                                : "previous"
                            }
                          >
                            <Link
                              to="#"
                              onClick={() => {
                                this.toggleTab(this.state.activeTab - 1);
                              }}
                            >
                              Previous
                            </Link>
                          </li>
                          <li
                            className={
                              this.state.activeTab === 4
                                ? "next disabled"
                                : "next"
                            }
                          >
                            <Link
                              to="#"
                              onClick={() => {
                                this.toggleTab(this.state.activeTab + 1);
                              }}
                            >
                              Next
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default compose(container)(pageAddEdit_EntityMembership);
