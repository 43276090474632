import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Row, Col, Card } from "reactstrap";

class CardShop extends Component {
  render() {
    console.log("color", this.props.shop.Color);
    console.log("shop ", this.props.shop);
    const name = this.props.shop.JobTitle;
    const nameIcon = name!=null > 0 && name.charAt(0).toUpperCase();
    return (
      <React.Fragment>
        <Col xl="4" sm="4">
          <Card className="job-card">
            <Row>
              <Link
                to={{
                  pathname: "/jobapplication",
                  state: { shop: this.props.shop },
                }}
                value={this.props.shop}
                className="mr-3 text-primary"
              >
                <div
                  className="cover d-flex flex-row align-items-center p-4 border-end"
                  id={"div" + this.props.shop.Id}
                  style={{ backgroundColor: this.props.colors }}
                >
                  <div className="avatar-sm mr-2">
                    <span
                      className="avatar-title"
                      style={{ width: "70px", height: "70px", backgroundColor : 'white'}}
                    >
                      <img
                        src={"https://api.societiesbank.com/MemberImages/" + 
                          this.props.shop.EntityLogo}
                        alt=""
                        className="rounded-circle"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </span>
                  </div>

                  <div>
                    <h5 style={{ marginLeft: "25px" }} className="pr-2 pl-2">
                      {this.props.shop.JobTitle}
                    </h5>
                    <h5
                      style={{ fontSize: "15px", marginLeft: "25px" }}
                      className="pr-2 pl-2"
                    >
                      Grade : {this.props.shop.Grade}
                    </h5>
                    <h5
                      style={{ fontSize: "15px", marginLeft: "25px" }}
                      className="pr-2 pl-2"
                    >
                      Closing Date : {this.props.shop.ClosingDateView}
                    </h5>
                  </div>
                </div>
              </Link>
            </Row>
            <Row className="p-3">
              <h5
                style={{
                  fontWeight: "bold",
                  color: "rgb(51, 102, 255)",
                  paddingRight: "",
                }}
                className=" p-1"
              >
                {this.props.shop.Organization}
              </h5>
            </Row>
          </Card>
        </Col>
      </React.Fragment>
    );
  }
}

CardShop.propTypes = {
  shop: PropTypes.object,
  selectcompany: PropTypes.func,
  colors: PropTypes.string,
};
export default CardShop;
