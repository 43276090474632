/* eslint-disable */

import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal
} from "reactstrap";

import classnames from "classnames";
import Select from "react-select";
import Dropzone from "react-dropzone";
import { useSelector } from "react-redux";
// Redux
import { withRouter, Link } from "react-router-dom";
// availity-reactstrap-validation
import {
  AvForm,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";


import SweetAlert from "react-bootstrap-sweetalert"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import { Fn_DisplayData, Fn_AddEditData, Fn_FillListData , Fn_TransactionWithdraw, Fn_ChangeStateValue } from "../../store/functions";




function formatNumber  (number)  {
    return `${number.substring(0, 3)}-${number.substring(3, 11)}-${number.substring(11)}`;
  };


class CashWithdrawl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      formData: {},
      activeTab: 1,
      passedSteps: [1],
      //dropdowns
      Accountdetail: [
        {
          Name : ""
        }
      ],
      RecipentDetail: [
        {
          Name : ""
        }
      ],
      prov : false,
      maritalStatus: [],
      accounttypevalue: 0,
      bloodGroup: [],
      gender: [],
      education: [],
      profession: [],
      relationShip: [],
      nationality: [],
      typeofRegCert: [],
      accounttype : [],
      SAccountType :0,
      MemberName : [
        {
          Name : '',
          MemberAccountNo : ''
        }
            ],
      success : 0,
      unsuccess_msg : false,
      nasty : {
      
      },
      BoldToken : [{
        Token : ''
      }],
    
      name : "",
      email : "",
      fo : 0,
      success_msg : false,
      VoucherId : 420,
      mobile : false,
      bank : false,
      BankDetails : [{}]
     



    };

  
  
   
    this.obj = this;
    this.formTitle = "CASH WITHDRAWAL ";
    this.breadCrumbTitle = "CASH WITHDRAWAL ";
    this.breadCrumbItem =  this.formTitle;
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/Membership";
    this.API_URL_SAVE = "AddEditWithdrawRequest/0/token";
    this.pushFormName = "/dashboard";
  

    //Event Binding
    this.btnSave_onClick = this.btnSave_onClick.bind(this);
    this.btnCancel_onClick = this.btnCancel_onClick.bind(this);
    this.syno =  this.syno.bind(this);
  
  }





  componentDidMount() {
    localStorage.setItem("PageName", this.breadCrumbItem)
    Fn_FillListData(this.obj, "BoldToken", API_WEB_URLS.MASTER + "/0/token/BoldToken/Id/0");
    
    //Filling DropDowns
    const obj = JSON.parse(localStorage.getItem("authUser"));
    this.setState({ name: obj.username, email: obj.email, aid: obj.uid , role:obj.role });
      Fn_FillListData(this.obj, "transactiontype", API_WEB_URLS.MASTER + "/0/token/TransactionType/Id/0");

      Fn_FillListData(this.obj, "BankDetails", API_WEB_URLS.MASTER + "/0/token/GetBankdetailsMember/Id/"+obj.uid);

  
  }
  btnSave_onClick(event, formData) {

    let vformData = new FormData();
   
    vformData.append("F_MemberMaster", this.state.aid);
    vformData.append("Amount", formData.Amount);
    vformData.append("F_Status", 0);
    vformData.append("Remarks", '');
    vformData.append("Mode", this.state.mode);
    vformData.append("MobileNo", this.state.MobileNo);
    
    if (!this.state.id)
    Fn_AddEditData(this.obj, { arguList: { id: 0, formData: vformData } }, this.API_URL_SAVE, "#", true, "VoucherId" );
  
  }

  btnCancel_onClick = event => {
    event.preventDefault();
    //this.props.history.goBack();
    this.props.history.push(this.pushFormName);
  };

  










  syno () {

    this.setState({
      success_msg : false,
      unsuccess_msg : false
    });
        

      };



 


 

  AmountCommission = (event)=> {

      let amount  =  event.target.value;
     
      let commission = amount*2/100;
  
      this.setState({ fo: Number(commission)+Number(amount) , Am : amount, Comm : commission });
    
  }

 



  
  



 


  handleCheckboxChange = (checkbox) => {
    let mode;
    if (checkbox === 'mobile') {
      mode = 1;
    } else if (checkbox === 'bank') {
      mode = 2;
    }
    this.setState({
      mobile: checkbox === 'mobile',
      bank: checkbox === 'bank',
      mode: mode
    });
  };
  




  render() {

    var parameters = [{
      name: 'Id',
     values: [this.state.VoucherId]
      }
    ];



    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.breadCrumbTitle}
              breadcrumbItem={this.breadCrumbItem}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                 
                    <div className="wizard clearfix">
                    
                            
                            <AvForm className="needs-validation" onValidSubmit={this.btnSave_onClick}>
                                <Row>
                                  <Col lg="6">
                                  <Card>
                                    <CardBody>
                                      <h4 className="card-title mb-4">Transaction Account</h4>
                                      <Row>
                                        <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label">GIN</label>
                                        </Col>
                                        <Col sm="6">
                                        <AvField name="GIN"   value={formatNumber(this.state.email)} placeholder="GIN"  type="text" className="form-control"  disabled/>
                                        </Col>
                                      </Row>
                                        <Row>
                                        <Col sm="4">
                                          <label htmlFor="membershipType" className="col-form-label"> Name</label>
                                        </Col>
                                        <Col sm="6">
                                        <AvField name="Name"  value={this.state.name}  placeholder="Name"   type="text" disabled className="form-control" />
                                        </Col>
                                      </Row>
                                    <Row>
                                    <Col sm="4">
                                                <label htmlFor="membershipType" className="col-form-label">Amount To Withdraw</label>
                                              </Col>
                                      <Col lg="6">
                                      <AvField name="Amount" onChange={this.AmountCommission}  value={this.state.formData.Amount} placeholder="Amount To Withdraw"    type="number" className="form-control" />
                                        </Col>
                                    </Row>


                                    <Row>
                                    <Col sm="4">
                                                <label htmlFor="membershipType" className="col-form-label">Cash Handling Commission</label>
                                              </Col>
                                      <Col lg="6">
                                      <AvField name="accountbalance"  value={this.state.Comm}  disabled    type="number" className="form-control" />
                                        </Col>
                                    </Row>
                                    <Row>
                                          <Col sm="4">
                                                      <label htmlFor="membershipType" className="col-form-label">Total Amount Debited to Account</label>
                                                    </Col>
                                            <Col lg="6">
                                            <AvField name="accountbalance"  value={this.state.fo} placeholder="Total Amount Debited to Account" disabled    type="number" className="form-control" />
                                              </Col>
                                          </Row>


                                          <Row>
                                          <Col sm="4">
                                                      <label htmlFor="membershipType" className="col-form-label">Withdraw Mode : </label>
                                                    </Col>
                                                    
                                            <Col lg="6">

                                            <label>
          <input
            type="checkbox"
            checked={this.state.mobile}
            onChange={() => this.handleCheckboxChange('mobile')}
          />
            Transfer to Mobile Phone No
        </label>
        <br />
        <label>
          <input
            type="checkbox"
            checked={this.state.bank}
            onChange={() => this.handleCheckboxChange('bank')}
          />
            	Transfer to Card
        </label>
                                              </Col>
                                          </Row>


{this.state.mobile ? 
                                          <Row>
                                          <Col sm="4">
                                                      <label htmlFor="membershipType" className="col-form-label">Mobile No.</label>
                                                    </Col>
                                            <Col lg="6">
                                            <AvField name="MobileNo" disabled  value={this.state.BankDetails[0].MobileNo_SMS} placeholder="MobileNo"      type="text" className="form-control" />
                                              </Col>
                                          </Row>

                                           : null}


{this.state.bank ? 

<>
                                          <Row>
                                          <Col sm="4">
                                                      <label htmlFor="membershipType" className="col-form-label">Bank Name</label>
                                                    </Col>
                                            <Col lg="6">
                                            <AvField name="BankName" disabled  value={this.state.BankDetails[0].BankName} placeholder="BankName"  type="text" className="form-control" />
                                              </Col>
                                          </Row>


                                          <Row>
                                          <Col sm="4">
                                                      <label htmlFor="membershipType" className="col-form-label">Bank Address</label>
                                                    </Col>
                                            <Col lg="6">
                                            <AvField name="BankAddress" disabled  value={this.state.BankDetails[0].BankAddress} placeholder="BankAddress"  type="text" className="form-control" />
                                              </Col>
                                          </Row>


                                          <Row>
                                          <Col sm="4">
                                                      <label htmlFor="membershipType" className="col-form-label">AccountNo</label>
                                                    </Col>
                                            <Col lg="6">
                                            <AvField name="AccountNo" disabled  value={this.state.BankDetails[0].AccountNo} placeholder="AccountNo"  type="text" className="form-control" />
                                              </Col>
                                          </Row>


                                          <Row>
                                          <Col sm="4">
                                                      <label htmlFor="membershipType" className="col-form-label">AccountType</label>
                                                    </Col>
                                            <Col lg="6">
                                            <AvField name="AccountTypeId" disabled  value={this.state.BankDetails[0].AccountTypeId  ==  2 ? 'Savings' : 'Current'} placeholder="AccountType"  type="text" className="form-control" />
                                              </Col>
                                          </Row>


                                          </>

                                           : null}


                          <div className="d-flex flex-wrap gap-2">
                            <Button type="submit" color="primary">
                              Save
                            </Button>{" "}

                             

                            {this.state.success_msg ? (
                      <SweetAlert
                        title="Transaction Successful!"
                        success
                       
                        confirmBtnBsStyle="success"
                      
                        onConfirm={this.syno}
                        
                      >
                       
                      </SweetAlert>
                    ) : null}

{" "}
                                {/* <Button
                          type="button"
                          color="secondary"
                          className="waves-effect"
                          onClick={this.btnReset_onClick}
                        >
                              Reset
                            </Button> */}
                            
                            {" "}
                                <Button
                          type="button"
                          color="secondary"
                          className="waves-effect"
                          onClick={this.btnCancel_onClick}
                        >
                              Exit
                            </Button>





                {this.state.unsuccess_msg ? (
                      <SweetAlert
                        title="Insufficient Amount!"
                        error
                       
                        confirmBtnBsStyle="success"
                      
                        onConfirm={this.syno}
                        
                      >
                       
                      </SweetAlert>
                    ) : null}
                            
                          </div>

</CardBody>
                      </Card>
                    </Col>
                    
                      </Row>
                    
                      </AvForm>
                    
                    </div>

                    <div>
                     
                     <Modal
                       size="xl"
                       isOpen={this.state.modal_fullscreen}
                       toggle={this.tog_fullscreen}
                       className="modal-fullscreen"
                     >
                       <div className="modal-header">
                         <h5
                           className="modal-title mt-0"
                           id="exampleModalFullscreenLabel"
                         >
                          Print
                         </h5>
                         <button
                           onClick={() =>
                             this.setState({ modal_fullscreen: false })
                           }
                           type="button"
                           className="close"
                           data-dismiss="modal"
                           aria-label="Close"
                         >
                           <span aria-hidden="true">&times;</span>
                         </button>
                       </div>
                       <div className="modal-body"  id="reportviewer">
                       <BoldReportViewerComponent id="reportviewer-container"
reportServiceUrl = {'http://shinewellsofttech.co.in:50693/reporting/reportservice/api/Viewer'}
reportServerUrl= {'http://shinewellsofttech.co.in:50693/reporting/api/site/site1'}
serviceAuthorizationToken = {'bearer '+this.state.BoldToken[0].Token}
reportPath = {'/Invoixe/WithdrawalReciept'}
parameters = {parameters}
>
</BoldReportViewerComponent>
                       </div>
                       <div className="modal-footer">
                         <button
                           type="button"
                           onClick={this.tog_fullscreen}
                           className="btn btn-secondary"
                           data-dismiss="modal"
                         >
                           Close
                         </button>
                     
                       </div>
                     </Modal>
                   </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default compose(container)(CashWithdrawl);
