import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import { Container, Card, CardBody, Col, Row, Modal } from "reactstrap";
import { Link } from "react-router-dom";
import CardShop from "./CardShop";

import { API_WEB_URLS } from "../../constants/constAPI";

import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import { Fn_FillListData } from "../../store/functions";

class pageList_NewOpportunity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Company: [],
      colors: "white",
      modal_xlarge: false,
    };

    this.obj = this;
    this.API_URL_COMPANY =
      API_WEB_URLS.MASTER + "/0/token/GetNewOpportunity/F_MemberMaster/";
    this.selectcompany = this.selectcompany.bind(this);
    this.Apply = this.Apply.bind(this);
  }
  tog_xlarge(item) {
    const newState = {
      Organization: item.Organization,
      Description: item.Description,
      NoOfBranches: item.NoOfBranches,
      NoOfEmployees: item.NoOfEmployees,
      modal_xlarge: !this.state.modal_xlarge,
      IMGURL_BusinessPlan: null,
      IMGURL_Transfer: null,
      IMGURL_Prospectus: null,
      IMGURL_OtherDoc: null
    };
  
    if (item.IMGURL_BusinessPlan != null) {
      newState.IMGURL_BusinessPlan = item.IMGURL_BusinessPlan;
    }
    if (item.IMGURL_Transfer != null) {
      newState.IMGURL_Transfer = item.IMGURL_Transfer;
    }
    if (item.IMGURL_Prospectus != null) {
      newState.IMGURL_Prospectus = item.IMGURL_Prospectus;
    }
    if (item.IMGURL_OtherDoc != null) {
      newState.IMGURL_OtherDoc = item.IMGURL_OtherDoc;
    }
  
    this.setState(newState);
  }
  
  Apply(Id){
    const obj = JSON.parse(localStorage.getItem("authUser"));

    Fn_FillListData(this.obj, "Company",API_WEB_URLS.MASTER + "/0/token/ApplySellBusiness/" + obj.uid+"/"+Id);
  }
  
  componentDidMount() {
    const obj = JSON.parse(localStorage.getItem("authUser"));

    Fn_FillListData(this.obj, "Company", this.API_URL_COMPANY + obj.uid);
  }

  selectcompany(shop) {
    var LastId = localStorage.getItem("F_CompanyMaster");

    if (LastId != null) {
      var element2 = document.getElementById("div" + LastId);
      element2.style.backgroundColor = "white";
    }

    var element = document.getElementById("div" + shop.Id);
    element.style.backgroundColor = "rgb(204, 214, 245)";
    localStorage.setItem("F_CompanyMaster", shop.Id);
    localStorage.setItem("FirmName", shop.Name);
    alert("You Selected - " + shop.Name);
  }

  render() {
    const shop = {
      name: "lk",
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Dashboard | Sahakaar Admin & Dashboard</title>
          </MetaTags>
          <Container fluid>
            <h4>Jobs</h4>

            <Row>
              {this.state.Company
                ? this.state.Company.map((item, key) => (
                    // <CardShop
                    //   color="black"
                    //   shop={option}
                    //   key={"_shop_" + key}
                    //   selectcompany={this.selectcompany}
                    //   colors={this.state.colors}
                    // />

                    <Col xl={3} md={6} key={key}>
                      <Card>
                        <CardBody>
                          <div className="favorite-icon">
                            <Link to="#">
                              <i className="uil uil-heart-alt fs-18"></i>
                            </Link>
                          </div>
                          <img
                            src={
                              "https://api.societiesbank.com/MemberImages/" +
                              item.EntityLogo
                            }
                            alt=""
                            height="50"
                            className="mb-3"
                          />
                          <h5 className="fs-17 mb-2">
                            <Link to="/job-details" className="text-dark">
                              {item.JobTitle}
                            </Link>
                          </h5>
                          <ul className="list-inline mb-0">
                            <li className="list-inline-item">
                              <h5 className="fs-17 mb-2">
                                <p className="text-muted fs-14 mb-1">
                                  {item.Organization}
                                </p>
                              </h5>
                            </li>{" "}
                            <ul></ul>
                            {/* <li className="list-inline-item">
                              <p
                                className="text-muted fs-14 mb-0 ml-0"
                                style={{ color: "black" }}
                              >
                                Grade : {item.Grade}
                              </p>
                            </li> */}
                          </ul>
                          {/* <div className="mt-3 hstack gap-2">
                            <span className="badge rounded-1 badge-soft-success">
                              Full Time
                            </span>
                            <span className="badge rounded-1 badge-soft-warning">
                              Urgent
                            </span>
                            <span className="badge rounded-1 badge-soft-info">
                              Private
                            </span>
                          </div> */}
                          <div className="mt-4 hstack gap-2">
                            <Link
                              onClick={() => this.tog_xlarge(item)}
                              to="#"
                              className="btn badge-soft-success w-100"
                            >
                              View Details
                            </Link>
                            <p
                            style={{marginTop:'16px'}}
                            onClick={()=>{
                                item.MemberId == null && this.Apply(item.Id)
                            }}
                           
                              className="btn badge-soft-info w-100"
                            >
                             {item.MemberId ==null ?"Apply Now" : "Applied"} 
                            </p>
                          </div>
                        </CardBody>
                      </Card>
                      <Modal
                        size="xl"
                        isOpen={this.state.modal_xlarge}
                        toggle={() => {
                          this.tog_xlarge();
                        }}
                      >
                        <div className="modal-header">
                          <h5
                            className="modal-title mt-0"
                            id="myExtraLargeModalLabel"
                          >
                            {this.state.Organization}
                          </h5>
                          <button
                            onClick={() => {
                              this.setState({
                                modal_xlarge: false,
                              });
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <Row>
                            <Col sm="3">
                              <h5>Description</h5>
                            </Col>
                            <Col sm="6">
                              <p>{this.state.Description}</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm="3">
                              <h5>NoOfBranches</h5>
                            </Col>
                            <Col sm="6">
                              <p>{this.state.NoOfBranches}</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm="3">
                              <h5>NoOfEmployees</h5>
                            </Col>
                            <Col sm="6">
                                
                            <p>{this.state.NoOfEmployees}</p>

                            </Col>
                          </Row>
                          <Row>
                            {this.state.IMGURL_BusinessPlan &&
<>
<Col sm="3">
                              <h5>BusinessPlan</h5>
                            </Col>
                            <Col sm="6">
                            <a href={"https://api.societiesbank.com/MemberImages/pdf/"+this.state.IMGURL_BusinessPlan}>View</a>
                            </Col>
</>
  }
                          
                          </Row>
                          <Row>
                            {this.state.IMGURL_Transfer && 
                            <>
                              <Col sm="3">
                              <h5>Transfer/sale Authorization</h5>
                            </Col>
                            <Col sm="6">
                            <a href={"https://api.societiesbank.com/MemberImages/pdf/"+this.state.IMGURL_Transfer}>View</a>
                            </Col>
                            </>
                            }
                          
                          </Row>
                          <Row>
                            {this.state.IMGURL_Prospectus&&
                            <>
                            <Col sm="3">
                              <h5>Prospectus</h5>
                            </Col>
                            <Col sm="6">
                            <a href={"https://api.societiesbank.com/MemberImages/pdf/"+this.state.IMGURL_Prospectus}>View</a>


                            </Col>
                            </>}
                          </Row>
                          <Row>
                            {this.state.IMGURL_OtherDoc &&
                           <>
                            <Col sm="3">
                              <h5>OtherDoc</h5>
                            </Col>
                            <Col sm="6">
                            <a href={"https://api.societiesbank.com/MemberImages/pdf/"+this.state.IMGURL_OtherDoc}>View</a>
                            </Col>
                           </>}
                          </Row>
                        </div>
                      </Modal>
                    </Col>
                  ))
                : null}
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default compose(container)(pageList_NewOpportunity);
