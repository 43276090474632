import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledTooltip
} from "reactstrap";
import Switch from "react-switch"
import classnames from "classnames";
import SweetAlert from "react-bootstrap-sweetalert"
import Select from "react-select";
import Dropzone from "react-dropzone";
import { useSelector } from "react-redux";
// Redux
import { withRouter, Link } from "react-router-dom";


// Editable
import BootstrapTable from "react-bootstrap-table-next"
import cellEditFactory from "react-bootstrap-table2-editor"
// availity-reactstrap-validation
import {
  AvForm,
  AvField,AvRadioGroup,AvRadio
} from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import { Fn_DisplayData, Fn_AddEditData, Fn_FillListData, Fn_ChangeStateValue, Fn_DeleteData } from "../../store/functions";
import AddDeleteTableRowsPly from "pages/Transaction/AddDeleteTableRowEMI";

export const DateString = (dd) =>{

let d1 =  dd.replace('-', '');
let d2 = d1.replace('-', '');

return d2;

}

class pageAddEdit_EditShift  extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      formData: {},
       activeTab: 1,
       passedSteps: [1],
       BrowseImage1 : '',
       BrowseImage2 : '',
       UserImage : '',
       Signature : '',
      //dropdowns
      membershipType: [],
      maritalStatus: [],
      bloodGroup: [],
      gender: [],
      education: [],
      profession: [],
      relationShip: [],
      nationality: [],
      typeofRegCert: [],
      memberid : 0,
      name : "",
      email : "",
      aid : 0,
      fo3 : false,
      Gender : 0,
      MaritalStatus : 0,
      success_msg : false,
      F_MISScheme : 0,
      IsOvertime : false,
      EmpBasic : [],
      save : false , cancel:false , add:true,
     
      //dropdowns
      
     
      EmpShift: [{
        F_EmployeeMaster:'',
        F_ShiftMaster1 : '',
        F_ShiftMaster2 : ''
      }]
    };
    this.obj = this;
    this.formTitle = "Edit Shift";
    this.breadCrumbTitle = "Memberships";
    this.breadCrumbItem = " " + this.formTitle;
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/City";
    this.API_URL_SAVE = API_WEB_URLS.AddEdit + "/0/token";
    this.pushFormName = "/#";
    this.rtPage_Print = "/#";

    //Event Binding
    this.btnSave_onClick = this.btnSave_onClick.bind(this);
    this.btnCancel_onClick = this.btnCancel_onClick.bind(this);
    this.btnGoBack_onClick = this.btnGoBack_onClick.bind(this);
    this.addrow = this.addrow.bind(this);
    this.editrow   = this.editrow.bind(this);
    this.deleterow  =  this.deleterow.bind(this);
  }

  componentDidMount() {

    const obj = JSON.parse(localStorage.getItem("authUser"));
    this.setState({ name: obj.username, email: obj.email, aid: obj.uid , role:obj.role });
 
  
       

       Fn_FillListData(this.obj, "Employee", API_WEB_URLS.MASTER + "/0/token/EmployeeMaster/Id/0");

       Fn_FillListData(this.obj, "Shift", API_WEB_URLS.MASTER + "/0/token/ShiftMaster/Id/0");


    const { id } = this.props.match.params;
    if (id) {
      this.setState({ id: id });
      this.breadCrumbItem = "Edit " + this.formTitle;
      Fn_DisplayData(this.obj, id, this.API_URL + "/Id");
    } else {
      this.setState({ id: 0 });
    }   
  }

btnSave_onClick(event, formData) {
  let vformData1 = new FormData();
  vformData1.append("F_LedgerMaster", formData.F_LedgerMaster);

 Fn_AddEditData(this.obj, { arguList: { id: 0, formData: vformData1 } }, "InsertPlyPartyDescriptionH/0/token", this.pushFormName, true);



 let vformData2 = new FormData();
 vformData2.append("F_LedgerMaster", formData.F_LedgerMaster);
 vformData2.append("Data", JSON.stringify(this.state.PlyParty));

  Fn_AddEditData(this.obj, { arguList: { id: 0, formData: vformData2 } }, "InsertPlyPartyDescriptionL/0/token", this.pushFormName, true);


}


deleterow(Id , index) {

  const rows = [...this.state.PlyParty];
    rows.splice(index, 1);
    this.setState({PlyParty: rows});
  Fn_DeleteData(this.obj, Id, "Masters/0/token/PlyPartyL", "Masters/0/token/PlyPartyL" + "/Id/0");
}






btnGoBack_onClick = event => {
  event.preventDefault();
  //this.props.history.goBack();
  this.props.history.push(this.pushFormName);
};



  btnCancel_onClick = event => {
    event.preventDefault();
    //this.props.history.goBack();
    this.props.history.push(this.pushFormName);
  };


  addrow() {

    
    let EmpShift  =  {
      F_EmployeeMaster:'',
      F_ShiftMaster1 : '',
      F_ShiftMaster2 : ''
     
    }
        this.setState({EmpShift: [...this.state.EmpShift, EmpShift]});
    
   
    }
    

      add () {
        this.setState({save : false , cancel:false , add:true});
        this.setState({ EmpShift: [{
          F_EmployeeMaster:'',
          F_ShiftMaster1 : '',
          F_ShiftMaster2 : ''
        }]});
    }




    OnLedgerChange = (event)=> {
      Fn_FillListData(this.obj, "PlyParty", API_WEB_URLS.MASTER + "/0/token/GetPlyPartyByLedger/Id/"+event.target.value);
    }


    editrow = (index, evnt) =>{
      const { name, value } = evnt.target;
  
      const rowsInput = [...this.state.PlyParty];
      rowsInput[index][name] = value;
  
      this.setState({PlyParty: rowsInput});
  
  }
  

    
  render() {   
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.breadCrumbTitle}
              breadcrumbItem={this.breadCrumbItem}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {/* <h4 className="card-title mb-4">Basic Wizard</h4> */}
                    <div className="wizard clearfix">
                     <div className="content clearfix">
                       <AvForm className="needs-validation" onValidSubmit={this.btnSave_onClick} >
                          <TabContent activeTab={this.state.activeTab} className="body">
                            <TabPane tabId={1}>
                                <Row>
                                  
                                  
                                  <Card>
                  <CardBody>
                  <AddDeleteTableRowsPly data={this.state.EmpShift} Employee={this.state.Employee} Shift={this.state.Shift}  add={this.addrow}  edit={this.editrow} del={this.deleterow}/>
                  </CardBody>
                </Card>
                                     
                                 </Row>    
                                                                                                                                                                                                         
                            </TabPane>                                                  
                          </TabContent>
                          <div>

                       

                          <Button
                          type="submit"
                          color="primary"
                          className="mr-1 waves-effect waves-light"
                        >
                          Save
                        </Button>
                        
                        &nbsp; 

                        <Button
                          type="button"
                          color="primary"
                          className="mr-1 waves-effect waves-light"
                          onClick={this.btnGoBack_onClick}
                        >
                          Go Back
                        </Button>
                        
                          </div>
                        </AvForm>
                      </div>                    
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default compose(container)(pageAddEdit_EditShift);