import { AvField } from "availity-reactstrap-validation";
import React  , {useRef} from "react";

function TableRowsEMI({rowsData ,course ,types ,add ,  edit, del}) {
  
  const F_CourseMaster1 = useRef([]);
  const F_TypeMaster1 = useRef([]);
  const Percentage1 = useRef([]);
  // const Year1 = useRef([]);
  const adds = useRef([]);

  const handleFocus = (event) => event.target.select();

  const handler2 = (index ,evnt , indo) => {

      const rowsInput = [...rowsData];   
      
      if (evnt.key == 'Enter') {

        if (index == 'F_CourseMaster')
        F_CourseMaster1.current[indo].focus();
        else if (index == 'F_TypeMaster')
        F_TypeMaster1.current[indo].focus();
        else if (index == 'Percentage')
        Percentage1.current[indo].focus();
        // else if (index == 'Years')
        // Year1.current[indo].focus();
       else if (index == 'Adds')
       adds.current[indo].focus();

        evnt.preventDefault();
      }

 };

    return(
        
        rowsData.map((data, index)=>{
          //  const {Id ,Course , Type, Percentage, Years }= data;
            const {Id ,F_CourseMaster , F_TypeMaster, Percentage, HPercentage }= data;
            return(
                <tr key={index}>
                   
                   <td> 
                      <select ref={(el) => (F_CourseMaster1.current[index] = el)}   style={{"width" : "200px"}}  
                      onChange={(evnt)=>(edit(index, evnt))} onKeyPress={(evnt)=>(handler2('Adds' ,evnt , index))}  
                      value={F_CourseMaster} name="F_CourseMaster"   className="form-select">
                        <option value={0} defaultValue label={"Course Desc..."} />
                              {course
                              ? course.map(
                               (option, key) => (
                               <option key={option.Id} value={option.Id} label={option.Name} />
                                 )
                               )
                              : null}
                      </select>
                    </td>

                    <td> 
                      <select ref={(el) => (F_TypeMaster1.current[index] = el)}   style={{"width" : "200px"}}  
                      onChange={(evnt)=>(edit(index, evnt))} onKeyPress={(evnt)=>(handler2('Adds' ,evnt , index))}  
                      value={F_TypeMaster} name="F_TypeMaster"   className="form-select">
                        <option value={0} defaultValue label={"Course City..."} />
                              {types
                              ? types.map(
                               (option, key) => (
                               <option key={option.Id} value={option.Id} label={option.Name} />
                                 )
                               )
                              : null}
                      </select>
                    </td>

                    <td>
                      <input
                          className="form-control"
                          type="number"
                          value={Percentage}
                          name="Percentage"
                          onFocus={handleFocus}
                          style={{"width" : "200px" , "height"  : "36px"}}
                          ref={(el) => (Percentage1.current[index] = el)}
                          onKeyPress={(evnt)=>(handler2('Adds' ,evnt , index))}
                          onChange={(evnt)=>(edit(index, evnt))}
                        /> 
                    </td>


                    <td>
                      <input
                          className="form-control"
                          type="number"
                          value={HPercentage}
                          name="HPercentage"
                          onFocus={handleFocus}
                          style={{"width" : "200px" , "height"  : "36px"}}
                          ref={(el) => (Percentage1.current[index] = el)}
                          onKeyPress={(evnt)=>(handler2('Adds' ,evnt , index))}
                          onChange={(evnt)=>(edit(index, evnt))}
                        /> 
                    </td>

                    {/* <td>
                        <input 
                          className="form-control"
                          type="date"
                          value={Years}
                          name='Years'
                          onFocus={handleFocus}
                          style={{"width" : "200px" , "height"  : "36px"}}
                          ref={(el) => (Year1.current[index] = el)} 
                          onKeyPress={(evnt)=>(handler2('Adds' ,evnt, index))}
                          onChange={(evnt)=>(edit(index, evnt))}
                        />
                    </td> */}

                  <td>
                  <button ref={(el) => (adds.current[index] = el)} onKeyPress={(evnt)=>(handler2('F_CourseMaster1' ,evnt , index+1))}  
                  type="button" className="btn btn-outline-success" onClick={add} >+</button>
                  </td>  
                  <td>
                  <button  className="btn btn-outline-danger" type="button" onClick={()=>(del(Id, index))}>x</button>
                  </td>  
            </tr>
            )
        })
   
    )
    
}
export default TableRowsEMI;