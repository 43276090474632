import React ,{useState , useRef} from "react";
import TableRowsEMI from "./TableRowsEMI";
function AddDeleteTableRowsEMI({  data ,course, types, add , edit ,  del}){

    const [rowsData, setRowsData] = useState([]);
  
   const deleteTableRows = (index)=>{
        const rows = [...rowsData];
        rows.splice(index, 1);
        setRowsData(rows);
   }

    return(
        <div className="table-responsive">
                <table className="" style={{"padding": "0" , "marginBottom" : "10px"}}>
                    <thead>
                      <tr>
                            <th>Course</th>
                            <th>City</th>  
                            <th>T Percentage</th>  
                            <th>H Percentage</th>  
                            {/* <th>Year</th>   */}
                            <th> <button   type="button" className="btn btn-outline-success" onClick={add} >+</button></th>    
                      </tr>
                    </thead>
                    <tbody>
                   <TableRowsEMI rowsData={data}  course={course} types={types} add ={add}  edit={edit} del={del}/>
                   </tbody> 
                </table>
              
        </div>
    )
}
export default AddDeleteTableRowsEMI