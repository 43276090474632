import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import SweetAlert from "react-bootstrap-sweetalert";
import { Container, Row } from "reactstrap";
import './dashboard.scss';
import { Fn_AddEditData, Fn_FillListData } from "store/functions";
import { API_WEB_URLS } from "constants/constAPI";
import { compose } from "recompose";
import { container } from "store/Containers/cntCommon";
import axios from "axios";



class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAlert: false,
      loadingAlert: false,
      suspendAlert: false,
      checkbox1: false,
      checkbox2: false,
      
    };
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/UpdateFirstLogin";
    this.obj = this;
  }

  componentDidMount() {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    if (!obj.IsFirstTime) {
      this.setState({ showAlert: true });
    }
    if (obj.IsFirstTime && !obj.IsPaid) {
      this.setState({ suspendAlert: true });
    }

    // Set state based on localStorage data
    this.setState({
      name: obj.username,
      email: obj.email,
      aid: obj.uid,
      role: obj.role,
    });
  }

  handleCheckboxChange = checkbox => {
    if (checkbox === "checkbox1") {
      this.setState({ checkbox1: true, checkbox2: false });
    } else if (checkbox === "checkbox2") {
      this.setState({ checkbox1: false, checkbox2: true });
    }
  };

  handleContinue = () => {
    this.setState({ showAlert: false });
    
    if(this.state.checkbox1 === true){
      console.log(' i am in checkBox 1 condition')
      const url = "https://api.paystack.co/transaction/initialize";
      const headers = {
        Authorization:
          "Bearer sk_live_1c89137206b2b42071d8cd6d8eba561ada311472",
        "Content-Type": "application/json",
      };
      //  const amount = Number(this.state.Amount) * 100; // Convert the amount to cents if it's in dollars
      const amount = 50 * 100;
      const totalAmount = amount + amount * 0.03; // Calculate 2% of the amount

      const formData = {
        email: this.state.email + "@gmail.com",
        amount: Number(totalAmount),
      };

      axios
        .post(url, formData, { headers })
        .then(response => {
          if (response.data.status) {
            // Redirect the user to the authorization URL
            window.open(response.data.data.authorization_url, "_blank");
            //window.location.href = response.data.data.authorization_url;
          } else {
            // Handle any error cases here
            console.error("Payment error:", response.data.message);
          }
        })
        .catch(error => {
          // Handle any errors here
          console.error("Payment error:", error);
        });
        this.setState({ loadingAlert: true });
    }
    else if(this.state.checkbox2 === true){
      console.log(' i am in checkBox 2 condition')
      const obj = JSON.parse(localStorage.getItem("authUser"));
      // this.setState({ showAlert: false });
      // this.setState({ suspendAlert: false });
      Fn_FillListData(this.obj, "gridName", this.API_URL + "/Id/" + obj.uid);
      this.props.history.push(`/logout`);
    }
    
  };
  handlePay = () => {
    this.setState({ suspendAlert: false });
    
    const url = "https://api.paystack.co/transaction/initialize";
    const headers = {
      Authorization: "Bearer sk_live_1c89137206b2b42071d8cd6d8eba561ada311472",
      "Content-Type": "application/json",
    };
    const amount  =  50*100;
    const totalAmount = amount + (amount * 0.03); // Calculate 2% of the amount
    
    const formData = {
      email: this.state.email+'@gmail.com',
      amount: Number(totalAmount),
      
    };
    
    axios
    .post(url, formData, { headers })
     .then(response => {
       if (response.data.status) {
         // Redirect the user to the authorization URL
         window.open(response.data.data.authorization_url, "_blank");
         //window.location.href = response.data.data.authorization_url;
        } else {
          // Handle any error cases here
          console.error("Payment error:", response.data.message);
        }
      })
      .catch(error => {
       // Handle any errors here
       console.error("Payment error:", error);
      });
      this.setState({ loadingAlert: true });
    };

    handleCancel = () => {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      
      this.setState({ showAlert: false });
      this.setState({ suspendAlert: false });
    //
    Fn_FillListData(this.obj,'gridName',this.API_URL + '/Id/'+obj.uid)

    this.props.history.push(`/logout`);
  };
    handleOk = () => {
      this.setState({ loadingAlert: false });
      this.props.history.push(`/logout`);
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
       
       
          <div style={{ backgroundColor: "blue !important" }}>
            <SweetAlert
              show={this.state.showAlert}
              title=""
              confirmBtnText="Continue"
              onConfirm={this.handleContinue}
              onCancel={this.handleCancel}
              showCancel
              customClass="blue-background"
            >
              <div>
                <div style={{ textAlign: "justify" }}>
                  <label>
                    <input
                      type="checkbox"
                      checked={this.state.checkbox1}
                      onChange={() => this.handleCheckboxChange("checkbox1")}
                    />

                    <span style={{ marginLeft: "20px" }}>
                      I agree to be contacted and to receive information by both
                      email and SMS. I am aware that the service is not free,
                      and subscribe for the Notification Service at a fee of
                      GH¢50
                    </span>
                  </label>
                </div>

                <br />
                <div style={{ textAlign: "justify" }}>
                  <label>
                    <input
                      type="checkbox"
                      checked={this.state.checkbox2}
                      onChange={() => this.handleCheckboxChange("checkbox2")}
                    />
                    <span style={{ marginLeft: "10px" }}>
                      I do not wish to subscribe for the Notification Service
                    </span>
                  </label>
                </div>
              </div>
            </SweetAlert>
          </div>
          <div>
            <SweetAlert
              show={this.state.suspendAlert}
              title=""
              confirmBtnText="Pay"
              onConfirm={this.handlePay}
              onCancel={this.handleCancel}
              showCancel
              customClass="blue-background"
            >
              <div style={{ textAlign: "justify" }}>
              Your account has been suspended.
Please, pay the notification fee 
to ACTIVATE the account.
              </div>
            </SweetAlert>
          </div>
          <div>
            <SweetAlert
              show={this.state.loadingAlert}
              title=""
              onConfirm={this.handleOk}
              showConfirm
            >
              <div style={{ textAlign: "justify" }}>
                You will re-directed to the secured
                payment page. Please, re-log in after successfully making
                payment.
              </div>
            </SweetAlert>
          </div>
          <MetaTags>
            <title>Dashboard | Sahakaar Admin & Dashboard</title>
          </MetaTags>
          <Container fluid>
            <h4>Dashboard</h4>

            <Row>{/* Reports Render */}</Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default compose(container) (Dashboard);
